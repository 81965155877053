import React from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";

const StyledButton = styled(ToggleButton)`
&&,&&:active {
  border-radius: ${props => props.round ? '20px' : 0};
  padding: 9px 14px;
  &.btn-secondary:not(:disabled):not(.disabled).active{
    background-color: ${({theme}) => theme.palette.primary.main};
  }

  //border-radius: 0;
  &.disabled{
    transform: none;
    text-decoration: line-through;
  }
  &:not(.disabled){
    border-color: ${({theme}) => theme.palette.grey["400"]};
    color: ${({theme}) => theme.palette.primary.dark};
    background-color: ${({theme}) => theme.palette.grey["100"]};
  }
  &:hover:not(.disabled) {
    background-color: ${({theme}) => theme.palette.grey["400"]};
    border-color: ${({theme}) => theme.palette.grey["500"]};
  }
  &.active,&.active:active {
    border-color: ${({theme}) => theme.palette.primary.dark};
    
  }
}
`;

/*
 * @values: [{value:string,label:string,checked:boolean,disabled}]
 * */
function ButtonsSelect({
                           className, type = "checkbox", values, onSelect,
                           variant = "secondary", radioName = "radio", round = true,
                       }) {
    return (
        <ToggleButtonGroup
            className={className + " select-button-group"}
            onChange={() => null}
            value={values.filter((val) => val.checked).map((value) => value.value)}
            type={type}
            style={{boxShadow: "none"}}
            aria-label="First group"
            name={type === "radio" ? radioName : undefined}
        >
            {values.map((val, i) => (
                <StyledButton
                    round={round}
                    className={"select-button"}
                    key={val.value}
                    name={val.value}
                    style={{boxShadow: "none"}}
                    onChange={() => onSelect(val, i)}
                    value={val.value}
                    disabled={val.disabled}
                    checked={val.checked}
                    variant={variant}
                >
                    {val.label}
                </StyledButton>
            ))}
        </ToggleButtonGroup>
    );
}

ButtonsSelect.propTypes = {
    values: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSelect: PropTypes.func.isRequired,
};

export default ButtonsSelect;
