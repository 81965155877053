import {useCallback} from "react";
import {useProjectParams} from "./useProject";
import Constants from "../controller/Constants";

export function useCorrectionFactorCalculator() {
  const projectParams = useProjectParams();
  const {antennaPolarization, devParams} = projectParams;
  const {systemGain} = devParams;
  const polarizationLoss = antennaPolarization === Constants.polarizationTypes[3] ? 3 : 0;
  const antennaGainTable = {
    TYPE_1X1: 9.0,
    TYPE_2X2: 9.0,
    TYPE_4X4: 12.0,
    TYPE_8X8: 15.5,
    TYPE_8X8_STEERED: 20.7,
    TYPE_16X16: 18.0,
    TYPE_32X32: 21.0,
    TYPE_64X64_STEERED: 24.0,
  };
  const callback = useCallback(
    ({antennaType, txPower, txLoss}) => {
      const antennaGain = antennaGainTable[antennaType];
      const correctionFactor = txPower - txLoss - polarizationLoss - systemGain + antennaGain;
      return correctionFactor;
    },
    [systemGain, polarizationLoss]
  );
  return callback;
}
