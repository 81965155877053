import React from 'react';
import MapContent from "./MapContent";
import useProject from "../../Hooks/useProject";
import {binsMapperBestServer} from "../../controller/binMappers";

function BestServerMap() {
    return (
        <MapContent
            binsMapper={binsMapperBestServer}
        />)
}

export default BestServerMap;