import React from 'react';
import Form from 'react-bootstrap/Form'
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import uniqid from 'uniqid';

const Fieldset = styled.fieldset`
  .form-label{
      font-size: 1.1rem;
  }
  .radio-form-group{
      margin-left: 0px;
      &.col{
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          padding-left: 0;
          padding-right: 0;
          margin-left: 2px;
          margin-right: 2px;
          .form-check{
            padding:10px;
            margin-right:15px;
          }
        }
  }
`;

function RadioList({className, values, currentValue, onItemClick, renderLabels, label}) {
    const RadioId = uniqid('RadioList-');
    return <Fieldset className={className}>
        {label && <Form.Label column sm="12">
            {label}
        </Form.Label>}
        <Form.Group
            className={'radio-form-group'}
            as={Col}>
            {values.map(item => (
                <Form.Check type={"radio"} className={'radio-list-item'}>
                    <Form.Check.Input onClick={() => onItemClick(item)} checked={currentValue === item} name={RadioId} type={"radio"} />
                    <Form.Check.Label onClick={() => onItemClick(item)}>{renderLabels(item)}</Form.Check.Label>
                    {/*<Form.Control.Feedback type="valid">You did it!</Form.Control.Feedback>*/}
                </Form.Check>)
            )}
        </Form.Group>
    </Fieldset>
}

RadioList.defaultProps = {
    inline: true,
    renderLabels: val => val,
    label: null,
};

RadioList.propTypes = {
    label: PropTypes.string,
    renderLabels: PropTypes.func,
    values: PropTypes.array.isRequired,
    currentValue: PropTypes.number.isRequired,
    onItemClick: PropTypes.func,
};

export default RadioList;