import React, {useEffect, useState} from "react";
import styled from 'styled-components';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import {connect} from "react-redux";
import {setHighlightOpacity} from "../../store/actionCreators/map";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Fade from 'react-bootstrap/Fade'
import {usePredictionState} from "../../Hooks/common";
import {useTranslation} from "react-i18next";

const Div = styled.div`
  position: absolute;
  top: 10px;
  background-color: #ffffff;
  right: 286px;
  .button-highlight{
      box-shadow: ${({theme}) => theme.shadows.map.control};
  }
`;
const ControlButton = styled(Button)`
    transition:0s;
    background-color: #ffffff ;
    color: rgba(0,0,0,0.7);
    &.btn.btn-lg{
        padding: 3px 7px;
        border-radius: 0;
    }
    i{
      transition:0s;
      font-weight: bold;
    }
    &:hover{
        color: rgba(0,0,0,1);
    }
`;
const StyledButton = styled(Button)`
&&{
   &.toggle-button{
     .fa{
       font-size: 15px;
     }
     background-color: #fff;
     border-color: #fff;
   }
   padding: 9px 14px;
   border-radius: 0;
}   
`;

function HighlightOpacityButtons({setHighlightOpacity, highlightOpacity}) {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const values = [0, 0.2, 0.4, 0.6, 0.8, 1];
    const [predictionState] = usePredictionState();
    useEffect(() => {
        setHighlightOpacity(values[0]);
        setOpen(false);
    }, [predictionState]);
    return <Div>
        <ButtonToolbar
            className="justify-content-between button-highlight"
            aria-label="Toolbar with Button groups"
        >
            <ButtonGroup style={{boxShadow: 'none'}} aria-label="First group">
                {<Fade
                    appear
                    in={open}>
                    <div>
                        {open && values.map(val => (<>
                            <StyledButton
                                style={{boxShadow: 'none'}}
                                onClick={() => {
                                    setHighlightOpacity(val)
                                }}
                                disabled={val === highlightOpacity}
                                key={val}
                                variant="secondary">{val === 0 && predictionState ? '-' : val}</StyledButton>{'  '}
                        </>))}
                    </div>
                </Fade>}
                <StyledButton
                    title={!open ?
                        t(predictionState ? 'set the prediction opacity' : 'set screen that shows the bins area')
                        : 'close'}
                    className={'toggle-button'}
                    style={{boxShadow: 'none'}}
                    onClick={() => {
                        setOpen(!open);
                    }}
                    variant="light">
                    {(open || Number(highlightOpacity) === 0) ? <i
                        className={"fa " + (!open ? 'fa-low-vision' : "fa-long-arrow-right")}
                        aria-hidden="true"/> : highlightOpacity}
                </StyledButton>
            </ButtonGroup>
        </ButtonToolbar>
    </Div>
}

function mapStateToProps(reduxState) {
    return {
        highlightOpacity: reduxState.map.highlightOpacity,
    }
}

export default connect(mapStateToProps, {setHighlightOpacity})(HighlightOpacityButtons);