import validate from "validate.js";
import Validator from "../../controller/Validator";

function siteModeToFieldName(siteMode) {
    const field = siteMode.toLowerCase().replace('d', 'D');
    return field;
}

export function addSectorToSiteAction(project, siteId, sector) {
    const newProject = {
        ...project, sites: project.sites.map(site => {
            if (site._id !== siteId) {
                const siteMode = siteModeToFieldName(sector.siteMode);
                return {
                    ...site,
                    [siteMode]: {
                        sectors: [...site[siteMode].sectors, sector]
                    }
                }
            }
            return site
        })
    };
    return newProject
}


function generateToastId(toasts) {
    const ids = toasts.map(toast => {
        return Number(toast.id)
    });
    return Math.max(0, ...ids) + 1;
}

function addToastAction(state, toastDetails) {
    if (validate.isString(toastDetails)) {
        toastDetails = {message: toastDetails};
    }
    if (toastDetails instanceof Error) {
        toastDetails = {
            error: true,
            message: toastDetails.message,
        }
    }
    if (Validator.isToast(toastDetails)) {
        const toastId = generateToastId(state.toasts);
        const newToast = {
            error: toastDetails.error,
            body: toastDetails.message || toastDetails.body,
            title: toastDetails.error ? "Failed" : 'Success',
            created: new Date(),
            id: toastId
        };
        return {
            ...state,
            toasts: [...state.toasts, newToast]
        };
    } else {
        throw Error('toastDetails object not valid');
    }
}

export {addToastAction}

export function addSiteToProjectAction(project, site) {
    return {
        ...project,
        sites: project.sites.map((siteI) =>
            siteI._id === site._id ? site : siteI
        ),
    }
}