import React, {useEffect, useState} from 'react';
import MapContent from "./MapContent";
import {connect} from "react-redux";
import {openDialog} from "../../store/actionCreators/general";
import Constants from "../../controller/Constants";
import {modals, events} from "../../controller/Constants";
import EventEmitter from "../../controller/EventEmitter";
import Loader from "../Loaders/Loader";
import {binsMapperC2I} from "../../controller/binMappers";
import {setMapType} from "../../store/actionCreators/map";

function C2IMap({setMapType, openDialog, currentDialog}) {
    const [chosenSites, setChosenSites] = useState([]);
    const [dialogChanged, setDialogChanged] = useState(false);
    useEffect(() => {
        openDialog(modals.CHOSE_2_SITES);
        const eventId = EventEmitter.subscribe(events.CHOSE_2_SITES, (sites) => {
            setChosenSites(sites.map(site=>site._id));
            // console.log('sites updated!');
        });
        return () => {
            EventEmitter.unsubscribe(events.CHOSE_2_SITES,eventId);
        };
    }, []);
    useEffect(() => {
        const isModal2Sites = currentDialog === modals.CHOSE_2_SITES;
        if (dialogChanged && !isModal2Sites && chosenSites.length !== 2) {
            setMapType(Constants.mapTypes.BEST_SERVER);
        }
        if (currentDialog === modals.CHOSE_2_SITES) {
            setDialogChanged(true);
        }

    }, [currentDialog, dialogChanged]);
    if (chosenSites.length !== 2) {
        return <Loader/>
    }
    return <MapContent
        binsMapper={binsMapperC2I}
        chosenSites={chosenSites}
    />
}

export default connect(state => ({currentDialog: state.general.currentDialog}), {openDialog, setMapType})(C2IMap);