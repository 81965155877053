import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTotalCost as setReduxTotalCost } from "../store/actionCreators/map";
import useProject from "./useProject";
import {useAddToast} from "./common";
import {getSiteSectors} from "../controller/dataOperations";
import {useDisplayedAntennas} from "../Hooks/displayedSectors"; // "../../Hooks/displayedAntennas"


function detectChange(project, displayedSectors) {
    
    // if(displayedSectors.constructor === Array){ // אדיר למה עשית את זה?
    //     return "list";
    // }
    return `${project._id || project.name}:${displayedSectors.sort(function (a, b) {
        if (a.sectorId < b.sectorId) {
            return -1;
        }
        if (a.sectorId > b.sectorId) {
            return 1;
        }
        return 0;
    }).map(({ sectorId, antennaModel, display }) => [sectorId, antennaModel, display ? "display" : "notDisplay"].join(', ')).join(';')}`;
}

function useCalcCost(spreadedSectors) {
    const project = useProject();
    const displayedAntennas = useSelector(state => state.map.displayedAntennas); 
    // const {displayedAntennas} = useDisplayedAntennas() ?
    const heightChangedString = detectChange(project, displayedAntennas);
    const totalCost = useSelector(state => state.map.totalCost);
    const addToast = useAddToast();

    const dispatch = useDispatch();
    const setTotalCost = useCallback((totalCost) => dispatch(setReduxTotalCost(totalCost)), [dispatch]);
    useEffect(() => {
        try {
            const totalCost = displayedAntennas.filter(displayedSector => displayedSector.display)
                .reduce((accm, displayedSector) => {
                    const site = project.sites.find(site => (String(site._id) === String(displayedSector.siteId)));
                    const sector = getSiteSectors(site).find(sector => (sector._id === displayedSector.sectorId));
                    const antennaPrice = sector?.antennaPrices[displayedSector.antennaModel];
                    return accm + Number(sector?.cost) + Number(antennaPrice);
                }, 0);
            setTotalCost(totalCost);
        } catch (e) {
            console.error(e);
        }
    }, [heightChangedString, spreadedSectors]);
}

function spreadSectors(project, addToast) {
    return project.sites.map(site => {
        try {
            return getSiteSectors(site).map((sector) => {
                const list = [sector.cost, ...Object.values(sector.antennaPrices)].join(',');
                return list;
            }).join(';');
        } catch (e) {
            console.error(e);
            addToast(Error('there was error calculating the total cost'));
            return null;
        }
    }).join(':');
}

function useTotalCost() {
    const project = useProject();
    const addToast = useAddToast();
    const totalCost = useSelector(state => state.map.totalCost);
    const spreadedSectors = spreadSectors(project, addToast);
    useCalcCost(spreadedSectors);
    return totalCost;
}

export default useTotalCost;


