import Constants from "../Constants";
import {fetchAuth, handleError, navigateResponse} from "./helper";

const {REST_URL} = Constants;


export const Sector = class {
    static async deleteSector(sectorId, siteId) {
        try {
            return await fetchAuth(`${REST_URL}sites/${siteId}/sectors/${sectorId}`, {
                method: 'DELETE'
            }).then(navigateResponse);
        } catch (e) {
            handleError(e);
        }
    }

    static async editSector(sector, siteId) {
        try {
            return await fetchAuth(`${REST_URL}sites/${siteId}/sectors/${sector._id}`, {
                method: 'PUT',
                body: JSON.stringify({sector: sector})
            }).then(navigateResponse);
        } catch (e) {
            handleError(e);
        }
    }

    static async createSector(sector, siteId) {
        try {
            return await fetchAuth(`${REST_URL}sites/${siteId}/sectors`, {
                method: 'POST',
                body: JSON.stringify({sector}),
            }).then(navigateResponse);
        } catch (e) {
            handleError(e);
        }
    }

    static async predict(siteId, sectorId, antennaModel) {
        try {
            return await fetchAuth(`${REST_URL}sites/${siteId}/sectors/${sectorId}/predict`, {
                method: 'POST',
                body: JSON.stringify({antennaModel})
            }).then(navigateResponse);
        } catch (e) {
            handleError(e);
        }
    }
};

