import {useDispatch, useSelector} from "react-redux";
import {setLocationsStats, setMapZoomCreator} from "../store/actionCreators/map";
import {useCallback} from "react";
import {colorMapper, getRssiLevels} from "../controller/mapper";
import {useProjectParams} from "./useProject";

export function useMapState() {
    return useSelector(state => state.map.mapState);
}

export function useMapZoom() {
    return useSelector(state => state.map.mapZoom);
}

export function useSetMapZoom() {
    const dispatch = useDispatch();
    return useCallback((zoom) => dispatch(setMapZoomCreator(zoom)),[dispatch]);
}

export function useLocationStatsState() {
    const dispatch = useDispatch();
    const setLocationStats = useCallback((locationStats) => dispatch(setLocationsStats(locationStats)), []);
    const locationStats = useSelector(state => state.map.locationStats);
    return [locationStats, setLocationStats];
}

export function useLocationStats() {
    return useSelector(state => state.map.locationStats);
}

export function useMapType() {
    return useSelector(state => state.map.mapType);
}

export function useRssiColor(rssi) {
    const mapType = useMapType();
    const projectParams = useProjectParams();
    if (!rssi) return '#fff';
    const {mapLayer, channelBW, rssiThreshold} = projectParams;
    const rssiLevels = getRssiLevels(mapType, mapLayer, channelBW, rssiThreshold);
    const binColor = colorMapper(rssi, rssiLevels);
    return binColor;

}