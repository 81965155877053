import {
    ADD_SECTOR_TO_SITE,
    ADD_SITE_TO_PROJECT,
    ADD_TOAST,
    RESET_PROJECT,
    SET_CURRENT_DIALOG,
    SET_DIALOG_SITE,
    SET_FETCH_LOADER,
    SET_PROJECT
} from '../actionTypes';
import Validator from "../../controller/Validator";
import Api from "../../controller/ApiManager/index";
import {useDispatch, useSelector} from 'react-redux';

export function resetProject() {
    return {type: RESET_PROJECT}
}

export function setFetchLoader(isFetchLoader) {
    return {
        type: SET_FETCH_LOADER,
        isFetchLoader,
    }
}

export function addToast(toastDetails) {
    return {
        type: ADD_TOAST,
        toastDetails
    }
}

export function addSectorToSiteAC(siteId, sector) {
    return {
        type: ADD_SECTOR_TO_SITE,
        sector,
        siteId
    }
}

export function addSiteToProjectAC(site) {
    return {
        type: ADD_SITE_TO_PROJECT,
        site,
    }

}

export function setProject(project) {
    return {
        type: SET_PROJECT,
        project,
    }
}

export async function setAsyncProject(project){
    return async dispatch => {
      const response = await Api.Project.editProject(project); 
      dispatch( {
        type: SET_PROJECT,
        project,
      });
    };
  };


export function closeDialog() {
    return openDialog(null)
}

export function openDialog(dialog) {
    return {
        type: SET_CURRENT_DIALOG,
        dialog
    }
}

export function setDialogSite(site) {
    if (Validator.isSite(site)) {
        return {
            type: SET_DIALOG_SITE,
            site: site
        }
    } else {
        throw Error('site not valid')
    }
}

export function setCurrentDialog(dialog) {
    return {
        type: SET_CURRENT_DIALOG,
        dialog: dialog
    }
}