import React from 'react';
import styled from "styled-components";

export const Container = styled.div`
    @-webkit-keyframes flip-horizontal-bottom {
        0% {
            -webkit-transform: rotateX(0);
            transform: rotateX(0);
        }
        100% {
          -webkit-transform: rotateX(-180deg);
          transform: rotateX(-180deg);
        }
    }
    @keyframes flip-horizontal-bottom {
        0% {
            -webkit-transform: rotateX(0);
            transform: rotateX(0);
        }
        100% {
            -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
        }
    }
    @-webkit-keyframes flip-horizontal-bottom-down {
        0% {
            -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
        }
        100% {
        -webkit-transform: rotateX(0);
                transform: rotateX(0);
        }
    }
    @keyframes flip-horizontal-bottom-down {
        0% {
            -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
        }
        100% {
            -webkit-transform: rotateX(0);
            transform: rotateX(0);
        }
    }
    position:absolute;
    top: 70px;
    left: 0;
    margin: 0 10px;
    z-index: 100;
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: center;
    .map-layer-card{
        width:172px;
        border-radius: 0px;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
        .card-header{
            overflow: hidden;
            border-radius: 0;
            cursor: pointer;
            padding:6px 10px;
            display:flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            color:${({theme}) => theme.palette.common.white};
            background-color: ${({theme}) => theme.palette.primary.main};
            .fa-angle-down,.fa-angle-up{
                flex-shrink: 0;
                transition: all 0.1ms ease-in;
                padding-top: 4.5px;
            }
            .title-layer{
                max-width:85%;
                flex-grow: 0;
            }
        }
        .list-group-item{
            padding:5px 4px 5px 10px;
        }
        .button-group-sf{
            padding:5px;
            display: flex;
            justify-content: space-around;
            flex-wrap: nowrap;
            .btn-primary:not(:disabled):not(.disabled):active:focus,.btn-outline-dark:not(:disabled):not(.disabled):active:focus{
              box-shadow: none;
            }
        }
    }
    .open-button{
        position: absolute;
        bottom: -40px;
        -webkit-animation: flip-horizontal-bottom-down 0.4s cubic-bezier(0.190, 1.000, 0.220, 1.000) both;
        animation: flip-horizontal-bottom-down 0.4s cubic-bezier(0.190, 1.000, 0.220, 1.000) both;
        height: 30px;
        width: 30px;
        padding: 0;
        margin: 5px;
        border-radius: 50%;
        background-color: ${({theme}) => theme.palette.common.white};
        &.flip-horizontal-bottom {
            -webkit-animation: flip-horizontal-bottom 0.4s cubic-bezier(0.190, 1.000, 0.220, 1.000) both;
            animation: flip-horizontal-bottom 0.4s cubic-bezier(0.190, 1.000, 0.220, 1.000) both;
        }
        .fa-arrow-down,.fa-arrow-up{
            font-size: 10px;
            padding-bottom: 5px;
            //transition: transform 300ms 400ms ease-in;
        }
    }
    .form-group-sf{
        display: flex;
        justify-content: start;
        flex-direction: row;
        margin:0;
        .form-label{
            flex-shrink:0;
            flex-basis: 110px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin: 0;
        }
        .form-control{
            text-align: end;
            padding: 0 3px;
            margin:0;
        }
    }
    select{
        text-align-last:end;
    }
`;