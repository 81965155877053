import React, {useCallback, useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {useTranslation} from "react-i18next";
import {UserApi} from "../../controller/ApiManager/index";
import {closeDialog} from "../../store/actionCreators/general";
import {useDispatch} from "react-redux";
import jwt from 'jsonwebtoken'
import {setUser} from "../../store/actionCreators/user";

function Login({...props}) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [fetching, setFetching] = useState(false);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const setUserRedux = useCallback(
        (payload) => dispatch(setUser(payload)),
        [dispatch]
    );
    const closeDialogRedux = useCallback(
        () => dispatch(closeDialog()),
        [dispatch]
    );
    useEffect(() => {
        props.setFetching(fetching);
        return () => {
            props.setFetching(false)
        }
    }, [fetching]);

    async function handleSubmit(ev) {
        try {
            ev.preventDefault();
            setFetching(true);
            const result = await UserApi.login({email, password});
            console.log(result);
            const userInfo = jwt.decode(result.tokens.idToken);
            console.log("userInfo: ", userInfo);
            const {email: tokenEmail} = userInfo;
            const idToken = `Bearer ${result.tokens.idToken}`;
            setUserRedux({
                user: {email: tokenEmail, groups: result.user.groups},
                idToken,
                refreshToken: result.tokens.refreshToken
            });
            closeDialogRedux();
        } catch (e) {
            console.log({e});
            console.error(e);
        } finally {
            setFetching(false)
        }
    }

    function emailChange(ev) {
        setEmail(ev.target.value);
    }

    function passwordChange(ev) {
        setPassword(ev.target.value);
    }

    return (
        <div className="modal-content">
            <Modal.Header>
                {/*<button type="button" className="close" data-dismiss="modal" aria-label="Close"><span*/}
                {/*aria-hidden="true">&times;</span></button>*/}
                <div className="section-title">
                    <h2><strong>Sign In</strong></h2>
                </div>
            </Modal.Header>
            <div className="modal-body">
                <form className="form inputs-underline" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email"
                               className="form-control"
                               name="email"
                               placeholder="Email"
                               onChange={emailChange}
                               value={email}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            className="form-control"
                            name="password"
                            placeholder="Password"
                            onChange={passwordChange}
                            value={password}
                        />
                    </div>
                    <div className="form-group center">
                        <button type="submit" className="btn btn-primary width-100">{t('Login_')}
                            {fetching ? <i className='fa fa-circle-o-notch fa-spin'/> : ''}
                        </button>
                    </div>
                    <hr/>
                    <p className="center note">
                        By clicking on “{t('Login_')}” button you are accepting the Terms & Conditions
                    </p>
                </form>
            </div>
        </div>)
}

export default Login;