import {combineReducers} from 'redux';
import user from './user';
import errors from './errors';
import map from './map';
import general from './general';

const rootReducer = combineReducers({
    user,
    errors,
    map,
    general
});
export default rootReducer;