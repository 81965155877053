import React, {useEffect, useState} from 'react';
import Toast from "react-bootstrap/Toast";
import styled from "styled-components";
import {connect} from "react-redux";

const StyledToast = styled(Toast)`
    margin-top: 10px;
    min-width: 200px;
`;

const StyledTiming = styled.small`
    margin-left: 5px;
    margin-bottom: -4px;
`;

function AlertToast({title, body, id, onClose, error, dispatch}) {
    const [minutes,setMinutes] = useState(0);
    const [show,setShow] = useState(true);
    useEffect(()=>{
        const intervalId = setInterval(()=>{
            if( minutes >= 5 ) onCloseWrapper();
            setMinutes(mins=>mins+1);
        },1000*60);
        return ()=>{
            clearInterval(intervalId);
        }
    },[]);
    function onCloseWrapper(){
        setShow(false);
        setTimeout(()=>{onClose(id)},400)

    }
    return <StyledToast show={show}
                        onClose={onCloseWrapper}
    >
        <Toast.Header>
            <i className={"fa fa-"+(error?"exclamation-triangle":'bell')} aria-hidden="true" style={{marginRight:10}}/>
            <strong className="mr-auto">{title||'No Title'}</strong>
            <StyledTiming>{minutes>1 ? minutes+' minutes ago' : minutes === 1 ? '1 minute ago' :'just now' }</StyledTiming>
        </Toast.Header>
        <Toast.Body>{body? body +'': 'No Body...'}</Toast.Body>
    </StyledToast>
}

export default connect()(AlertToast);


