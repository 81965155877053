import React from 'react';
import styled from "styled-components";
import {ClassicSpinner} from "react-spinners-kit";


const Div = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  z-index: 1100;
  background-color: rgba(255,255,255,0.4);
  .classic__Wrapper-sc-1ycp7u6-0{
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
  }
`;

function FullScreenLoader({}) {
    return <Div>
        <ClassicSpinner size={50} color="#686769" loading={true}/>
    </Div>
}

export default FullScreenLoader;