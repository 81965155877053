import {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {addToast, setCurrentDialog,setFetchLoader as setFetchLoaderCreator, openDialog} from "../store/actionCreators/general";
import {
    setPolygonPath,
    setPredictionState as setPredictionStateCreator,
    showCostsTable as showCostsTableCreator
} from "../store/actionCreators/map";
import {setUser} from "../store/actionCreators/user";

export function useSetCurrentDialog(){
    const dispatch = useDispatch();
    return useCallback((dialogName)=>{
        dispatch(setCurrentDialog(dialogName));
    })
}

export function useAddToast() {
    const dispatch = useDispatch();
    const addToastRedux = useCallback((toastMessage) => dispatch(addToast(toastMessage)), [dispatch]);
    return addToastRedux;
}


export function useUserGroups() {
    const user = useSelector(state => state.user.user);
    return user.groups;
}

export function usePredictionState() {
    const predictionState = useSelector(state => state.map.predictionState);
    const dispatch = useDispatch();
    const setPredictionState = useCallback((predictionState) => {
        dispatch(setPredictionStateCreator(predictionState))
    }, [dispatch]);
    return [predictionState, setPredictionState];
}

export function useFetchLoader() {
    const isFetchLoader = useSelector(state => state.general.isFetchLoader);
    const dispatch = useDispatch();
    const setFetchLoader = useCallback(isFetchLoader => {
        dispatch(setFetchLoaderCreator(isFetchLoader));
    }, [dispatch]);
    return [isFetchLoader, setFetchLoader];
}

export function useSetFetchLoader() {
    return useFetchLoader()[1];
}

export function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => clearTimeout(handler);
    }, [value, delay]);
    return debouncedValue;
}

export function useShowCostsTable() {
    const dispatch = useDispatch();
    const showCostsTable = useCallback((show) => dispatch(showCostsTableCreator(show)), []);
    return showCostsTable;
}

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}
export function usePolygonPath(){
    return useSelector(state=>state.map.polygonPath);
}

export function usePolygonPathState(){
    const dispatch = useDispatch();
    const polygonPath = usePolygonPath();
    const setPolygonPathCB = useCallback((path)=>{
        dispatch(setPolygonPath(path));
    },[dispatch]);
    return [polygonPath,setPolygonPathCB];
}

export function useAlertFirstRender(message){
    const firstRender = useRef(true);
    useEffect(()=>{
        if(firstRender.current) {
            firstRender.current = false;
            window.alert(message);
        }
    });
}

export function useSetUserRedux() {
    const dispatch = useDispatch();
    const setUserRedux = useCallback((user) => dispatch(setUser(user)), [dispatch]);
    return setUserRedux;
}

export function useOpenDialogs() {
    const dispatch = useDispatch();
    const openDialogs = useCallback((dialog) => dispatch(openDialog(dialog)), [dispatch]);
    return openDialogs;
}
