String.prototype.capitalize = function (options) {
    const {all = false, single = true/* force pascal case eg: */} = options || {};
    return (all) ? // If all words
        this.split(' ').map(word => word.capitalize()).join(' ') :
        this.charAt(0).toUpperCase() + (single ? this.substr(1).toLowerCase() : this.slice(1));
};

if (!String.prototype.trim) {
    String.prototype.trim = function () {
        return this.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
    };
}

Array.prototype.insert = function ( index, item ) {
    this.splice( index, 0, item );
    return this;
};