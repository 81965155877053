import React from 'react';
import styled from "styled-components";
import ProgressBar from "react-bootstrap/ProgressBar";

const StyledProgress = styled(ProgressBar)`
    margin-bottom: 10px;
    &.progress{background-color: #dcdcdc;}
`;

function PercentProgress({className, percent}) {
    function mapVariant(statElement) {
        if (statElement >= 80) {
            return 'success'
        }
        if (statElement >= 50) {
            return 'warning'
        }
        return 'danger'
    }

    return <StyledProgress className={className} variant={mapVariant(percent)} now={percent}
                           label={`${percent.toFixed(2)}%`}/>

}

export default PercentProgress;