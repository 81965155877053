import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import React, {useContext} from "react";
import AccordionContext from "react-bootstrap/AccordionContext";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {useAccordionToggle} from "react-bootstrap";
import SiteDetails from "./Site/SiteDetails";
import PropTypes from "prop-types";


const StyledCard = styled(Card)`
    .bold{
      font-weight: bold;
    }
`;
const AddSiteButton = styled.i`
  cursor: pointer;
  float: right;
  margin-top: 5px;
`;

function ContextAwareToggle({ eventKey, title, callback, ...props }) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return <Card.Header className={props.bold ? 'bold' : ''} onClick={decoratedOnClick}>
        {title}
        <AddSiteButton
            {...props}
            className={isCurrentEventKey ? 'fa fa-minus-circle fa-lg' : 'fa fa-plus-circle fa-lg'}

        />
    </Card.Header>
}


function CardAccordion({bold, eventKey, children, title, ...props}) {
    const {t} = useTranslation();
    return (
        <StyledCard>
            <ContextAwareToggle bold={bold} title={title || t('No_Title')} eventKey={eventKey || "0"}/>
            <Accordion.Collapse eventKey={eventKey || "0"}>
                <div>
                    <Card.Body> 
                        {children}
                    </Card.Body>
                </div>
            </Accordion.Collapse>
        </StyledCard>)
}

CardAccordion.defaultProps = {
    eventKey: '0',
};
CardAccordion.propTypes = {
    eventKey: PropTypes.any,
    title:PropTypes.string
};
export default CardAccordion;