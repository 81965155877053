import React from 'react';
import {MarkerClusterer, Marker as GoogleMarker, useGoogleMap} from "@react-google-maps/api";
import Marker from "./Marker";
import {useMapZoom} from "../../Hooks/map";
import {isLatLngInBounds} from "../../controller/dataOperations";

const options = {
    imagePath:
        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
};

function filterByMarker(mapZoom) {
    return (site) => {
        if (mapZoom > 15) return true;
        return true;
    }
}

function inScope(mapBounds) {
    return site => mapBounds ? isLatLngInBounds(site.location, mapBounds) : false;
}

function SitesClusterer({sites, chosenSites, mapBounds}) {
    const map = useGoogleMap();
    const mapZoom = useMapZoom();
    const sitesInScope = sites.filter(inScope(mapBounds));
    const markerSites = sites.filter(filterByMarker(mapZoom, mapBounds));
    const showOverlays = Boolean(sitesInScope.length < 10);

    function positionMap(location) {
        map.setCenter(location);
        map.setZoom(Math.max(mapZoom + 1, 15));
    }

    return <>
        {/*sites display*/}
        <MarkerClusterer
            // options={options}
        >
            {(clusterer) => !showOverlays && markerSites.map((site, i) => {
                return <GoogleMarker
                    onClick={() => positionMap(site.location)}
                    clusterer={clusterer}
                    position={site.location}
                />
            })}
        </MarkerClusterer>
        {showOverlays && sitesInScope.map((site, i) => {
            return <Marker
                key={site.location.lat + ',' + site.location.lng}
                title={chosenSites ? (i === 0 ? 'Main' : 'Secondary') : ''}
                site={site}
            />
        })}
    </>
}

SitesClusterer = React.memo(SitesClusterer);
export default SitesClusterer;