import React, {useState} from 'react';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import Modal from "react-bootstrap/Modal";
import BackButton from "../StyledComponents/BackButton";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {closeDialog} from "../../store/actionCreators/general";
import SiteCard from "../Site/SiteCard";
import Alert from "react-bootstrap/Alert";
import EventEmitter from "../../controller/EventEmitter";
import {events} from "../../controller/Constants";
import {isSitesEqual} from "../../controller/dataOperations";
import {setDisplayedAntennas} from "../../store/actionCreators/map";
import {addToast, openDialog} from "../../store/actionCreators/general";


const ModalContent = styled.div`
        width:30vw;
`;
const SubmitButton = styled(Button)`
    &.btn[type=submit]{
        width: 250px;
        height:50px;
      margin-top: 20px !important;
    }
`;

function Chose2SitesU({t, onBack, project, closeDialog, setSelectedHeights,}) {
    const projectToWorkWith = project;
    const [mainSite, setMainSite] = useState({});
    const [secondSite, setSecondSite] = useState({});
    const [alert, setAlert] = useState('');

    function choseSite(site) {
        setAlert('');
        if (isSitesEqual(mainSite, site)) {
            return setMainSite({})
        }
        if (isSitesEqual(secondSite, site)) {
            return setSecondSite({})
        }
        if (Object.keys(mainSite).length === 0) {
            setMainSite(site);
            return;
        }
        if (Object.keys(secondSite).length === 0) {
            setSecondSite(site);
            return;
        }
        return setAlert('You can\'t chose more then two sites!, to select another please remove one before...')
    }

    function chose() {
        if (Object.keys(mainSite).length > 0 && Object.keys(secondSite).length > 0) {
            setSelectedHeights([]);
            EventEmitter.dispatch(events.CHOSE_2_SITES, [mainSite, secondSite]);
            closeDialog();
        } else {
            setAlert('Please select two sites to proceed..');
        }

    }

    return (
        <ModalContent>
            <Modal.Header closeButton>
                <div className="section-title">
                    <h2>{t('Choose_2_Sites')}</h2>
                    {typeof onBack === 'function' &&
                    <BackButton className='btn btn-outline-light btn-rounded' onClick={onBack}/>}
                </div>
            </Modal.Header>
            <Modal.Body>
                {alert !== '' && <Alert variant={'danger'}>{alert}</Alert>}
                {projectToWorkWith.sites.map(site => (
                    <SiteCard site={site}
                              onClick={choseSite}
                              check={isSitesEqual(site, mainSite) ? t('Main_Site') : isSitesEqual(site, secondSite) ? t('Secondary_Site') : ''}/>
                ))}
                <Form.Group className="center">
                    <SubmitButton onClick={chose} variant={'primary'}>{t('Chose_Sites')}</SubmitButton>
                </Form.Group>
            </Modal.Body>
        </ModalContent>
    )
}

const Chose2Sites = withTranslation()(Chose2SitesU);

function mapStateToProps(reduxState) {
    return {
        project: reduxState.general.project,
        currentDialog: reduxState.general.currentDialog,
    }
}

export default connect(mapStateToProps, {
    addToast,
    setSelectedHeights: setDisplayedAntennas,
    closeDialog,
    openDialog
})(Chose2Sites);