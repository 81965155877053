import React, {useCallback} from "react";
import styled from "styled-components";
import MultiSelectItem from "./MultiSelectItem"
import PropTypes from 'prop-types';


const StyledFormCheck = styled('div')`
    overflow-x: hidden;
    transition: background-color 0.2s 0.05s,border-color 0.2s 0.05s,color 0.2s 0.05s;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: start;
    position: relative;
    border: 2px solid ${({theme}) => theme.palette.grey[300]};
    font-size: 10px;
    &:hover:not(.active){
      cursor:pointer;
      border-color:${({theme}) => theme.palette.grey[300]};
    }
    &.active{
      cursor:pointer;
      border:2px solid ${({theme}) => theme.palette.secondary.main} ;
      color:${({theme}) => theme.palette.secondary.dark};
    }
    .height-param{
      margin-bottom: 0;
      font-size: 14px;
      transition: background-color 0.2s 0.05s,border-color 0.2s 0.05s,color 0.2s 0.05s;
      position: relative; 
      height: auto;
      width: 100%;
      padding: 8px;
      padding-left: 35px;
      //border-bottom: 2px solid red;
    }
    &:not(.active) .height-param:hover {
        background-color:${({theme}) => theme.palette.grey[300]};
        //border-bottom: 4px solid #e0e0e0;
    }
`;

function MultiSelectList({className, onActive, optionsList, active, onSelect, inputType, onSelectAll}) {
    const itemClick = item => () => { // note function that return function!
        if (typeof onActive === 'function') {
            onActive(item.value)
        } else {
            onSelect(item.value)
        }
    };

    const checkboxClick = (item) => () => {
        onSelect(item.value)
    };


    return <div className={className}>
        {typeof onSelectAll === 'function' && inputType === 'checkbox' && <StyledFormCheck>
            <h6 className={'height-param'}>select all</h6>
            <MultiSelectItem
                isChecked={optionsList.every(item => item.checked)}
                inputType={inputType}
                onSelect={onSelectAll}>
            </MultiSelectItem>
        </StyledFormCheck>}
        {optionsList.map((item) => (
            <StyledFormCheck
                key={item.value}
                onClick={itemClick(item)}
                className={(item.value === active && active) ? 'active' : ''}>
                <h6 className={'height-param'}>{item.label}</h6>
                {item.value && <MultiSelectItem
                    isChecked={item.checked}
                    inputType={inputType}
                    onSelect={checkboxClick(item)}>
                </MultiSelectItem>}
            </StyledFormCheck>
        ))}
    </div>
}

MultiSelectList.propTypes = {
    optionsList: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        label: PropTypes.string.isRequired,
        checked: PropTypes.bool,
    })).isRequired,
    active: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onActive: PropTypes.func,
    onSelect: PropTypes.func.isRequired,
    onSelectAll: PropTypes.func,
    inputType: PropTypes.oneOf(['checkbox', 'radio'])
};

export default MultiSelectList;