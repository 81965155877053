import {useCallback, useEffect, useRef, useState} from 'react';
import EventEmitter from "../controller/EventEmitter";
import {events} from "../controller/Constants";

function useCheckOffSet(dialogRef, above, setAbove) {
    return useCallback(() => {
        const elDistanceToTop = window.pageYOffset + dialogRef.current.getBoundingClientRect().top;
        const aboveBool = above ?
            elDistanceToTop >= 40 :
            elDistanceToTop > 83 + dialogRef.current.offsetHeight - 13 + 40;
        if (aboveBool !== above) {
            setAbove(aboveBool);
        }
    }, [above]);
}

function useCheckOffSets(dialogRef, checkOffset, dialog) {
    useEffect(() => {
        if (dialogRef.current) {
            checkOffset();
        }
    }, [Boolean(dialogRef.current), dialog]);
}


function useManageAboveDialogs(dialogRef, above, setAbove) {
    useEffect(() => {
        const id = EventEmitter.subscribe(events.CENTER_CHANGED, () => {
            if (dialogRef.current) {
                const elDistanceToTop = window.pageYOffset + dialogRef.current.getBoundingClientRect().top;
                const aboveBool = above ?
                    elDistanceToTop >= 40 :
                    elDistanceToTop > (83 - 13) + dialogRef.current.offsetHeight + 40;
                    if (aboveBool !== above) {
                        setAbove(aboveBool);
                    }
                }
            });
            return () => {
                EventEmitter.unsubscribe(events.CENTER_CHANGED, id);
            };
        }, [above]);
    }

    function usePreventDialogAffectMap(dialog, dialogRef) {
        const init = useRef(false);
        useEffect(() => { //code to prevent gestures on dialog to affect the map.
            if (dialog && dialogRef.current && !init.current) {
                window.google.maps.OverlayView.preventMapHitsAndGesturesFrom(dialogRef.current);
                init.current = (true);
            } else if (!dialog) {
                init.current = false;
            }
        }, [dialog, dialogRef.current]);
    }

    export default function useDialogDynamicPositon(dialogRef,dialog){
        const [above, setAbove] = useState(true);
        const checkOffset = useCheckOffSet(dialogRef, above, setAbove);
        useCheckOffSets(dialogRef, checkOffset, dialog);
        useManageAboveDialogs(dialogRef, above, setAbove);
        usePreventDialogAffectMap(dialog, dialogRef);
        return above;
    }