 const mapStyles = [
    // {
    //     featureType: "administrative.land_parcel",
    //     stylers: [
    //         {
    //             visibility: "off"
    //         }
    //     ]
    // },
    // {
    //     featureType: "administrative.neighborhood",
    //     stylers: [
    //         {
    //             visibility: "off"
    //         }
    //     ]
    // },
    {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [
            {
                visibility: "off"
            }
        ]
    },
    // {
    //     featureType: "road",
    //     elementType: "labels",
    //     stylers: [
    //         {
    //             visibility: "off"
    //         }
    //     ]
    // },
    {
        featureType: "water",
        elementType: "labels.text",
        stylers: [
            {
                visibility: "off"
            }
        ]
    }
];


 export default mapStyles;