import React, {forwardRef} from "react";
import PropTypes from 'prop-types';
import {Autocomplete} from '@react-google-maps/api'

function PlacesAutoComplete(
    {value, defaultValue, onLoad, onPlaceChanged, placeholder, inputStyle, className, inputProps, ...props}, ref) {
    return <Autocomplete
        onLoad={(AutoCompleteRef) => {
            ref.current = AutoCompleteRef;
            if (typeof onLoad === 'function') {
                onLoad(AutoCompleteRef);
            }
        }}
        onPlaceChanged={onPlaceChanged}
        {...props}>
        <input
            {...inputProps}
            className={className + (inputProps?.disabled ? " disabled" : '')}
            defaultValue={defaultValue}
            value={value}
            type="text"
            placeholder={placeholder}
            style={inputStyle}
        />
    </Autocomplete>
}

PlacesAutoComplete = forwardRef(PlacesAutoComplete);
PlacesAutoComplete.defaultProps = {
    defaultValue: '',
    onLoad: (ref) => {
        console.log(ref)
    },
    placeholder: '',
    inputStyle: {},
    className: undefined,
    inputClassName: undefined,
};

PlacesAutoComplete.propTypes = {
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    onLoad: PropTypes.func,
    onPlaceChanged: PropTypes.func.isRequired,
    inputStyle: PropTypes.object,
    inputProps: PropTypes.object,
    className: PropTypes.string,
};

export default PlacesAutoComplete;