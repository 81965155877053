import React, {forwardRef, useEffect, useRef} from 'react';
import FormCheck from 'react-bootstrap/FormCheck';
import styled from "styled-components";

const StyledSelectButton = styled('div')`
    .checkbox-button{
        position: absolute;
        top: 5px;
         left: 5px;
        height: auto;
        width: auto; 
    }
    .checkbox-label{
        display: inline-block;
        padding: 16px;
        position: absolute;
        top: -5px;
        left: -4px;
        height: auto;
        width: auto;
        transition: 0.4s;
        border-radius:50%;
    }
    .checkbox-label:hover{
        background-color: #ddd;
        cursor:pointer;
    }
    .checkbox-input{
        position: absolute;
        margin-top: -6px;
        margin-left: -7px;
        cursor:pointer;
    }
`;


function MultiSelectItem({isChecked, onSelect, inputType, ...props}) {
    return (<StyledSelectButton>
        <FormCheck
            className="checkbox-button">
            <div className="checkbox-label"
                 onClick={ev => {
                     ev.preventDefault();
                     onSelect(isChecked);
                     ev.stopPropagation();
                 }}>
                <FormCheck.Input
                    {...props}
                    className="checkbox-input"
                    checked={Boolean(isChecked)}
                    onClick={ev => {
                        onSelect(isChecked);
                        ev.stopPropagation();
                    }}
                    type={inputType === 'switch' ? 'checkbox' : inputType}
                />
            </div>
        </FormCheck>
    </StyledSelectButton>);
}

export default MultiSelectItem;