import React from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import MultiSelectList from "../Common/MultiSelect/MultiSelectList";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

const StyledModalBody = styled(Modal.Body)`
    max-height: 150px;
    overflow-y: scroll !important;
    padding: 0 0 !important;
    .nav-tabs{
         border-bottom-color: ${({theme}) => theme.palette.secondary.dark};
         border-bottom-width: 2px};
    }
    .nav{
      flex-wrap: nowrap;
      .nav-link{
        color:${({theme}) => theme.palette.grey[900]};
        padding:7px 0 5px 0;
        text-align: center;      
        flex-grow: 1;
        flex-shrink:0;
        flex-basis:50%;
        border-top-left-radius:  10px;
        border-top-right-radius: 10px;
      }
      .nav-link.active{
        border-width: 1.5px;
        font-size: 12px;
        border-color: ${({theme}) => theme.palette.secondary.dark};
        color: ${({theme}) => theme.palette.secondary.dark};
        border-bottom-color: transparent;
      }
      .nav-link.disabled{
        color:${({theme}) => theme.palette.grey[300]};
        text-decoration: line-through;
      }
    }
    .tab-content > .tab-pane{
        padding-top: 0px;
    }
`;

function SectorsDialogBody({notEmpty, typesOptions, sectorsOptions, active, disabled, 
                            onModelActive, onSectorActive, onSelectSector, handleTooltip, ...props}) {
    const {t} = useTranslation();
    return(
        <StyledModalBody>   
            <Tabs defaultActiveKey="sectors"  onSelect={handleTooltip}>
                <Tab 
                    eventKey="sectors" 
                    title={t("Heights_t")}>
                       {notEmpty && <MultiSelectList
                            optionsList={sectorsOptions}
                            inputType={"checkbox"}
                            active={active}
                            onActive={onSectorActive}
                            onSelect={onSelectSector}
                        />}
                </Tab>
                <Tab
                    eventKey="antenna types"
                    title={t("Type_t")}
                    disabled={disabled}> 
                        {notEmpty && <MultiSelectList
                            optionsList={typesOptions}
                            inputType={"radio"}
                            onActive={onModelActive}
                            onSelect={onModelActive}
                        />}
                </Tab>
            </Tabs>
        </StyledModalBody>
    );
}

export default SectorsDialogBody;