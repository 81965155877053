export class LocalDisplayedAntennasManager {

    static fromDisplayedAntennas(antennas) {
        return antennas.map(antenna => ({...antenna, active: false}));
    }

    static setActiveById(antennas, id) {
        return antennas.map(antenna => ({...antenna, active: antenna.sectorId === id}));
    }


    static setFirstSectorActive(antennas) {
        const newAntennas = antennas.map(antenna => ({...antenna, active: false}));
        newAntennas[0].active = true;
        return newAntennas
    }

    static getActiveSectorId(antennas) { //getActiveAntennaId?
        return antennas.find(antenna => antenna.active)?.sectorId;
    }

    static toggleDisplayById(antennas, id) {
        return antennas.map(antenna => {
            if (antenna.sectorId === id) {
                return {...antenna, display: !antenna.display};
            }
            return {...antenna};
        });
    }

    static updateActiveAntennaModel(antennas, newAntennaModel) {
        return antennas.map(antenna => {
            if (antenna.active) {
                return {...antenna, antennaModel: newAntennaModel};
            }
            return {...antenna};
        });
    }
}

export class DisplayedAntennasManager {s
    static fromProject(project) {
        const sites = project?.sites || [];
        const sectors = sites.flatMap(site => ([...site.preDesign.sectors, ...site.postDesign.sectors]
            .map(sector => ({...sector, siteId: site._id}))));
        const antennas = sectors.map(sector => DisplayedAntennasManager.fromSector(sector));
        return antennas;
    }

    static fromSector({_id, siteId, antennaModels, siteMode}) {
        return {
            sectorId: _id,
            siteId: siteId,
            antennaModel: antennaModels[0],
            display: false,
            conversion: {from: null, to: null,},
            siteMode: siteMode
        }
    }

    static setDisplayByIds(antennas, ids) {
        return antennas.map(antenna => ({...antenna, display: ids.includes(antenna.sectorId)}));
    }

    static getUpdatedAntennas(antennas, antennasToUpdate, displayIds) {
        const antenasAfterDisplayUpdate = DisplayedAntennasManager.setDisplayByIds(antennas, displayIds);
        return antenasAfterDisplayUpdate.map(antenna => {
            const antennaToUpdate = antennasToUpdate.find(ant => ant.sectorId === antenna.sectorId);
            if (antennaToUpdate || displayIds.includes(antenna.sectorId)) {
                return {
                    ...antenna,
                    antennaModel: antennaToUpdate?.antennaModel || antenna.antennaModel,
                    conversion: antennaToUpdate?.conversion || antenna.conversion,
                };
            }
            return {...antenna, display: false};
        });
    }

    static setDisplayAll(antennas, isDisplay) {
        return antennas.map(antenna => ({...antenna, display: isDisplay}))
    }
}