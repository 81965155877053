import React, { forwardRef } from 'react';
import { useProjectState } from "../../Hooks/useProject";
import mongoose from 'mongoose';
import { useAddToast, usePolygonPathState } from "../../Hooks/common";
import projectParamsSchema from '../../controller/models/projectParams';
import { useTranslation } from "react-i18next";
import FileManager from "./FileManager";

function PolygonFileManager(props, ref) {

    const [project, setProject] = useProjectState();
    const [polygonPath, setPolygonPath] = usePolygonPathState();
    const addToast = useAddToast();
    const { t } = useTranslation();
    const exportPolygonFile = polygonPath;

    function validatePlygonParams(parsedFile) {
        let doc = null;
        let err = null;
        for (const mapLayersParam of parsedFile) {
            doc = new mongoose.Document(mapLayersParam, new mongoose.Schema({ projectParamsSchema }));
            err = doc.validateSync();
            if (err) throw err;
        }
    }

    function updatePolygon(file) {  
        const parsedFile = JSON.parse(file);
        const valid = true;   // validatePlygonParams(parsedFile);
        if (valid) {
            // setFetching(true); ? ? ? 
            setPolygonPath(parsedFile);
        }
    }

    function getFileName() {
        return `polygon-${polygonPath.length}-points-${project.name}-${new Date(Date.now()).toLocaleString().split(',')[0].replaceAll(".", "-")}.json`;
    }

    return (
        <FileManager
            ref={ref}
            fileName={getFileName()}
            data={exportPolygonFile}
            onFileChange={updatePolygon}
            accept=".json"
        />
    );
}

export default forwardRef(PolygonFileManager);