import React from 'react';
import Spinner from "react-bootstrap/Spinner";
import styled from 'styled-components';

const Div = styled.div`
    width: 100%;
    height: 100%;
    .spinner-border{
        position: relative;
        top: calc(50% - 12.5px);
        left: calc(50% - 12.5px);
    }
`;

function LoaderSpinner() {
    return <Div>
        <Spinner animation="border"/>
    </Div>
}

export default LoaderSpinner;