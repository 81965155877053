import Validator from "../Validator";
import Constants from "../Constants";
import {fetchAuth, handleError, navigateResponse} from "./helper";
import {application} from "../../App";

const {REST_URL} = Constants;


export const Site = class {
    static getProjectId() {
        const storeState = application.store.getState();
        return storeState.general.project._id;
    }

    static async createSite(siteDetails,projectIdOptional) {
        try {
            const projectId = projectIdOptional || this.getProjectId();
            if (Validator.isNewSite(siteDetails) && projectId){
                const res = await fetchAuth(Constants.REST_URL + 'projects/' + projectId + '/sites', {
                    method: 'POST',
                    body: JSON.stringify({site: siteDetails}),
                });
                const siteResult = await navigateResponse(res);  
                return {...siteResult};
            }
            else if (!Validator.isNewSite(siteDetails))
                throw new Error(`not a vaild Site in "editSite" function`);
            else
                throw new Error(`project miss '_id' property in "editSite" function`);
        }catch (e) {
            handleError(e);
        }
    }

    static async editSite(site) {
        const projectId = this.getProjectId();
        if (Validator.isSite(site) && projectId) {
            return await fetchAuth(Constants.REST_URL + 'projects/' + projectId + '/sites/' + site._id, {
                method: 'PUT',
                body: JSON.stringify({
                    site: site,
                })
            }).then(navigateResponse).catch(handleError);
        } else if (!Validator.isSite(site))
            return Promise.reject(Error(`not a vaild Site in "editSite" method in Api.Site Class `));
        else
            return Promise.reject(Error(`project id missing in "editSite" function`));
    }

    static async uploadFileToSector(site, sector, file) {
        const fileData = await new Promise((res,rej)=>{
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = function () {
                res(reader.result);
            };
            reader.onerror = function (error) {
                rej(error);
            };
        });
        console.log({fileData});
        const siteId = typeof site === 'string' ? site:site._id;
        try {
            if (!siteId || !sector || !file) throw Error('missing data in uplaod funcion');
            const formData = new FormData();
            formData.append('file', file);
            const {file: dummy, ...sectorNoFile} = sector;
            formData.append('s3path', `sites/${siteId}/sectors/${sectorNoFile._id}/`);
            formData.append('sector', JSON.stringify(sectorNoFile));
            formData.append('siteId', siteId);
            return await fetchAuth(REST_URL + 'sites/' + siteId + '/file', {
                method: 'POST',
                body: formData
            }).then(navigateResponse).catch(handleError);
        } catch (e) {
            handleError(e);
        }
    }

    static async getSite(siteId) {
        try {
            return await fetchAuth(Constants.REST_URL + 'sites/' + siteId, {
                method: 'GET'
            }).then(navigateResponse);
        } catch (e) {
            handleError(e);
        }
    }

    static async deleteSite(siteId, projectId) {
        try {
            return await fetchAuth(`${Constants.REST_URL}projects/${projectId}/sites/${siteId}`, {
                method: 'DELETE'
            }).then(navigateResponse);
        } catch (e) {
            handleError(e);
        }
    }
};