import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import Constants, {modals} from "../../controller/Constants";
import SitesManager from "../Site/SitesManager";
import LoaderSpinner from "../Loaders/LoaderSpinner";
import Api from "../../controller/ApiManager/index";
import {closeDialog} from "../../store/actionCreators/general";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import DeleteButton from "../Common/Buttons/DeleteButton";
import {setProjects} from "../../store/actionCreators/map";
import {addToast, setCurrentDialog, setDialogSite} from "../../store/actionCreators/general";
import {useFetchLoader} from "../../Hooks/common";
import {useProjectState} from "../../Hooks/useProject";
import ImageInput from "../Common/Inputs/ImageInput";

const ImageInputStyled = styled.div`
  margin-left: 15px;
  margin-bottom: 40px;
`;

const ModalContent = styled.div`
  width: 45vw;
  .align-right {
    text-align: end;
  }
`;

const SpinnerContainer = styled.div`
  width: 100%;
  height: 200px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  background-color: rgba(0, 0, 0, 0.03);
`;

const Col_Sm = styled.div`
  margin-bottom: 20px;
`;

const SaveChangesButton = styled(Button)`
  margin: 20px auto;
  &.btn {
    padding: 10px 20px;
    font-size: 16px;
  }
`;

const DEFAULT_ALERT = {
  message: "",
  error: false,
};

function EditProject({closeDialog, addToast, setDialogSite, setCurrentDialog, setProjects, projects}) {
  const {t} = useTranslation();
  const [alert, setAlert] = useState(DEFAULT_ALERT);
  const [inputs, setInputs] = useState({name: ""});
  const [loading, setLoading] = useFetchLoader();
  const [project, setProject] = useProjectState();

  useEffect(() => {
    setInputs({...inputs, name: project.name});
  }, [
    project.name,
    project._id,
    project.sites &&
      project.sites
        .map((site) => site._id)
        .sort()
        .join(),
  ]);

  async function updateSite(site) {
    try {
      setLoading(true);
      const siteUpdated = await Api.Site.editSite(site, project._id);
      const newSites = project.sites.map((mapSite) =>
        mapSite._id === siteUpdated._id
          ? {
              ...siteUpdated,
              preDesign: {sectors: mapSite.preDesign.sectors},
              postDesign: {sectors: mapSite.postDesign.sectors},
            }
          : mapSite
      );
      setProject({...project, sites: newSites});
      setAlert({message: `site ${siteUpdated.displayName} changed successfully!`});
    } catch (error) {
      console.error(error);
      setAlert({message: "there was problem changing " + site.displayName + " site.", error: true});
    } finally {
      setLoading(false);
    }
  }

  async function addSite(siteDetails) {
    try {
      setLoading(true);
      const newSite = await Api.Site.createSite(siteDetails);
      setAlert({message: `Site "${newSite.displayName}" successfully added to project "${project.name}"`});
      setProject({...project, sites: [...project.sites, newSite]});
    } catch (err) {
      setAlert({error: true, message: "error while fetching data"});
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  async function saveProjectChanges() {
    try {
      setLoading(true);
      const projectUpdated = await Api.Project.editProject({...project, name: inputs.name});
      setProject({...projectUpdated, mapLayersParams: project.mapLayersParams, sites: project.sites});
      setAlert({message: "project successfully changed"});
    } catch (err) {
      console.error(err);
      setAlert({message: "Error Changing project", error: true});
    } finally {
      setLoading(false);
    }
  }

  function goToEditSiteDialog(site) {
    setDialogSite(site);
    setCurrentDialog(modals.EDIT_SITE);
  }

  async function deleteSite(site, index) {
    try {
      setLoading(true);
      const toDelete = window.confirm("Are_You_Sure");
      if (!toDelete) return;
      const response = await Api.Site.deleteSite(site._id, project._id);
      // const projectUpdated = await Api.Project.getProject(project._id, {binsPopulated: true});
      setProject({...project, sites: project.sites.filter((siteI) => siteI._id !== site._id)});
      setAlert({message: `Site "${site.displayName}" successfully deleted from project "${project.name}"`});
    } catch (e) {
      setAlert({message: "Error Deleting Site, please see Logs...", error: true});
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  async function deleteProject() {
    try {
      setLoading(true);
      const toDelete = window.confirm("Are_You_Sure");
      if (!toDelete) return;
      const response = await Api.Project.deleteProject(project._id);
      // const projectUpdated = await Api.Project.getProject(project._id, {binsPopulated: true});
      addToast(`Successfully deleted Project "${project.name}" from Database!`);
      setProjects(projects.filter((projectI) => projectI._id !== project._id));
      closeDialog();
    } catch (e) {
      setAlert({message: "Error Deleting Project, please see Logs...", error: true});
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  async function handleUploadImage(image) {
    try {
      if (image.size > 1000000) throw Error("It is not possible to upload an image above 10 MB");
      setLoading(true);
      const response = await Api.uploadImage(image);
      if (!response.ok)
        // if get status 200 but error in the response?? i shuld print the error messege
        throw Error(`Error uploading image`, {cause: response});
      addToast(`Successfully added sites-image to Project "${project.name}"!`);
      const projectUpdated = await Api.Project.editProject({
        ...project,
        sitesImage: `https://flycomm-cover-rest-api-${process.env.REACT_APP_STAGE}-images.s3.eu-west-1.amazonaws.com/${image.name}`,
      }); // take out the url to constants?
      setProject({...projectUpdated, mapLayersParams: project.mapLayersParams, sites: project.sites});
    } catch (e) {
      setAlert({message: "Error uploading image, please see logs...", error: true});
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <ModalContent>
      <Modal.Header closeButton>
        <div className="section-title">
          <h2>{t("Edit_Project_Title")}</h2>
        </div>
      </Modal.Header>
      <Modal.Body>
        {alert.message && (
          <Alert
            dismissible
            onClose={() => {
              setAlert(DEFAULT_ALERT);
            }}
            variant={alert.error ? "danger" : "success"}
          >
            {alert.message}
          </Alert>
        )}

        <div className="row">
          <Col_Sm className="col-md-9 col-sm-9">
            <Form.Group>
              <Form.Label column={""} htmlFor="project_name">
                {t("Project_Name")}
              </Form.Label>
              <Form.Control
                key={0}
                type="text"
                name="project_name"
                placeholder={t("Project_Name_Placeholder")}
                onChange={(ev) => setInputs({...inputs, name: ev.target.value})}
                value={inputs.name}
              />
            </Form.Group>
          </Col_Sm>
          <div className="col-md-3 col-sm-3 align-right">
            <DeleteButton label={t("Delete_Project")} onClick={deleteProject} />
          </div>
          <Col_Sm className="col-md-9 col-sm-9">
            <ImageInputStyled>
              <Form.Label column={false}>{t("Project_Image")}</Form.Label>
              <ImageInput onImageChange={handleUploadImage} imageUrl={project.sitesImage || Constants.DEFAULT_PROJECT_SITES_IMAGE} />
            </ImageInputStyled>
          </Col_Sm>
          <div className="col-sm-12">
            {project && project.sites ? (
              <SitesManager
                project={project}
                onSiteUpdated={updateSite}
                onAddSite={addSite}
                onGoToSite={goToEditSiteDialog}
                onSiteDeleted={deleteSite}
                setAlert={setAlert}
              />
            ) : (
              <SpinnerContainer>
                <LoaderSpinner />
              </SpinnerContainer>
            )}
          </div>
          <SaveChangesButton onClick={saveProjectChanges}>
            {t("Save_Project_Changes") + "   "}
            {loading ? <i className="fa fa-circle-o-notch fa-spin" /> : ""}
          </SaveChangesButton>
        </div>
      </Modal.Body>
    </ModalContent>
  );
}

export default connect((state) => ({projects: state.map.projects}), {
  closeDialog,
  addToast,
  setDialogSite,
  setCurrentDialog,
  setProjects,
})(EditProject);
