import Form from "react-bootstrap/Form";
import React, {useEffect, useRef, useState} from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";
import FileInput from "./FileInput"


const StyledWrapper = styled.div`
  display:flex;
  margin-bottom: 20px;
`;
 

function ImageInput({imageUrl, onImageChange}) { 
    return (
        <StyledWrapper>
            {imageUrl  && <img height="70px" width="70px" style={{padding:"5px"}} src={imageUrl}></img>}
            <FileInput
              style={{flex:'1'}}
              onFileChange={onImageChange}
              placeHolder='Click or drag image here'
              disablePlaceHolder='' 
            />
          {/* <FileInput onFileChange={(file)=> setImage(URL.createObjectURL(file))}/> */}
        </StyledWrapper>
             )
}


export default ImageInput;