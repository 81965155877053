import React from 'react';
import styled from "styled-components";
import Modal from 'react-bootstrap/Modal'

const SmallModal = styled(Modal.Dialog)`
    .modal-header{
        padding: 7px 16px;
        .h4.modal-title{
            font-size: 20px;
        }
    }
`;

export default SmallModal