import React ,{Component} from 'react';
import ProgressBar from "react-bootstrap/ProgressBar";
import logo13 from '../../images/logo/logo-13.png'
import logo14 from '../../images/logo/logo-14.png'
class Loader extends Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <div className={'loader-container'}>
                <img className="logo-icon-loader" src={logo13} alt=""/>
                <img className="logo-text-loader" src={logo14} alt=""/>
                <div style={{width:'100%'}}>
                    <ProgressBar  animated now={100}  variant="warning" />
                </div>
                {/*<i className="fa fa-spinner fa-pulse fa-5x fa-fw"></i>*/}
                <span className="sr-only">Loading...</span>
            </div>
        )
    }
}

export default Loader;