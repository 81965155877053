import React from 'react';
import Modal from "react-bootstrap/Modal";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import Table from "react-bootstrap/Table";
import useProject from "../../Hooks/useProject";
import {useDisplayedAntennas} from "../../Hooks/displayedSectors";

const TableContainer = styled.div`
  position: absolute;
  top: 40px;
  right:20px;
  background-color: transparent;
  z-index: ${({theme}) => theme.zIndex.modal || 100};
`;

// style to make table scrollable
const StyledTable = styled(Table)`
   overflow-y: auto; 
    height: 25vh;
   display: block;
   box-shadow: inset 1px -1px rgb(222, 226, 230);
   td{
     box-shadow: inset 1px -1px rgb(222, 226, 230);
   }
   th{
     box-shadow: inset 1px 1px rgb(222, 226, 230), 0 1px rgb(222, 226, 230);
   }
   thead th { 
      position: sticky;
      top: 0; 
      background-color: white;
   }
   tfoot td { 
      position: sticky;
      bottom: 0; 
      background-color: ${({footerColor}) => footerColor};

   }
`;

function DistributionTable({onClose: handleCloseDialog}) {
    const project = useProject();
    const {t} = useTranslation();
    const displayedAntennas = useDisplayedAntennas(); 

    function sortBySiteName(a, b) {
        try {
            const siteA = project.sites.find((site) => site._id === a.siteId);
            const siteB = project.sites.find((site) => site._id === b.siteId);
            if (siteA.displayName < siteB.displayName) {
                return -1;
            }
            if (siteA.displayName > siteB.displayName) {
                return 1;
            }
            return 0;
        } catch (e) {
            console.error(e);
            return 0;
        }

    }

    return <TableContainer>
        <Modal.Dialog scrollable>
            <Modal.Header
                closeButton
                onHide={handleCloseDialog}
            >
                <Modal.Title>{t('Sites_Cost')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <StyledTable className="table table-striped header-fixed" striped bordered hover
                             footerColor={displayedAntennas.length % 2 === 0 ? '#fff' : "#f2f2f2"}>
                    <thead>
                    <tr>
                        <th>{t('Index_th')}</th>
                        <th>{t('Height_th')}</th>
                        <th>{t('Antenna_th')}</th>
                        <th>{t('Cost_th')}</th>
                        <th>{t('Site_th')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {displayedAntennas && displayedAntennas.filter(displayedSector => displayedSector.display)
                        .sort(sortBySiteName).map((displayedSector, i) => {
                            try {
                                const site = project.sites.find((site) => site._id === displayedSector.siteId);
                                const sectors = site.preDesign.sectors.concat(site.postDesign.sectors);
                                const sector = sectors.find(sector => sector._id === displayedSector.sectorId);
                                const antennaModel = displayedSector.antennaModel;
                                const antennaPrice = sector.antennaPrices[antennaModel];
                                const antennaModelString = t(antennaModel);
                                return (<tr key={displayedSector.site}>
                                    <td>{i + 1}</td>
                                    <td>{sector.height + ' ' + t('_M_th')}</td>
                                    <td title={antennaModelString}>{antennaModelString}</td>
                                    <td
                                        title={`${t('site_price')}: $${Number(sector.cost).toLocaleString()}\n${antennaModelString}: $${antennaPrice.toLocaleString()}`}
                                    >
                                        ${Number(sector.cost).toLocaleString() + ' + $' + antennaPrice.toLocaleString()}
                                    </td>
                                    <td>{site.displayName}</td>
                                </tr>);
                            } catch (e) {
                                return <tr key={i}/>;
                            }
                    })
                    }
                    </tbody>
                    <tfoot>
                    <tr key={null}>
                        <td><strong>{t('Total_')}</strong></td>
                        <td/>
                        <td/>
                        <td>
                            <strong>${Number(displayedAntennas.filter(displayedSector => displayedSector.display)
                            .reduce((acc, displayedSector) => {
                                try {
                                    const site = project.sites.find((site) => site._id === displayedSector.siteId);
                                    const sectors = site.preDesign.sectors.concat(site.postDesign.sectors);
                                    const sector = sectors.find(sector => sector._id === displayedSector.sectorId);// todo try catch
                                    const antennaPrice = sector.antennaPrices[displayedSector.antennaModel];
                                    return acc + sector.cost + antennaPrice;
                                }
                                catch (e) {
                                    return 0;
                                }
                            }, 0)).toLocaleString()}</strong>
                        </td>
                        <td/>
                    </tr>
                    </tfoot>
                </StyledTable>
            </Modal.Body>
        </Modal.Dialog>
    </TableContainer>
}


export default DistributionTable;