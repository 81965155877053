import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import ProjectRow from "../ProjectRow";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import useProject from "../../Hooks/useProject";
import {closeDialog} from "../../store/actionCreators/general";
import {useFetchLoader} from "../../Hooks/common";
import Form from "react-bootstrap/Form";


const Div = styled.div`
  &.modal-content{
    width:50vw !important; 
  }
`;


function ProjectList({projects, closeDialog, ...props}) {
    const currentProject = useProject();
    const projectId = currentProject && currentProject._id;
    const {t} = useTranslation();
    const [search, setSearch] = useState('');
    const isFirstRun = useRef(true);
    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        closeDialog()
    }, [projectId]);
    return (
        <Div className={'modal-content'}>
            <Modal.Header closeButton>
                <div className="section-title">
                    <h2>{t('Projects_Title')}</h2>
                </div>
            </Modal.Header>
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <Form.Group>
                            <Form.Label htmlFor="project_name" column={false}>{t('Search_Project')}</Form.Label>
                            <Form.Control type="text" name="project_name"
                                          placeholder={t("Enter_Project_Name")}
                                          onChange={(ev) => setSearch(ev.target.value)}
                                          value={search}
                            />
                        </Form.Group>
                    </div>
                </div>
                {projects && projects.length > 0 ? projects
                    .filter(project => project.name.toLowerCase().includes(search.toLowerCase()))
                    .map(project => (
                        <ProjectRow
                            key={project._id}
                            project={project}
                        />
                    )) : 'No Projects Available...'}
            </div>
        </Div>)
}


function mapStateToProps(reduxState) {
    return {projects: reduxState.map.projects};
}

export default connect(mapStateToProps, {closeDialog})(ProjectList);