import React, {useEffect, useRef, useState} from 'react'
import {GoogleMap as Map, LoadScript, useLoadScript} from '@react-google-maps/api'
import styled from 'styled-components';
import mapStyles from '../../configuations/mapStyles.js'
import Constants from '../../controller/Constants'
import useProject from "../../Hooks/useProject";

const StyledGoogleMap = styled(Map)`
   height: 100%;
   width: 100%;
`;
const libraries = ["places"];

function GoogleMap({onLoad, onCenterChanged, onBoundsChanged, children, ...props}) {
    const project = useProject();
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: Constants.GOOGLE_MAPS_API_KEY, // ,
        libraries: libraries,
        // id:'example-map',
    });
    // const [load, setLoad] = useState(false);
    const mapOptions = {
        scrollwheel: true,
        streetViewControl: false,  //don't show the  pegman
        styles: mapStyles,
        disableDoubleClickZoom: true,
        clickableIcons: false,
        rotateControl: true,
    };

    function onMapLoaded(mapInstance) {
        if (typeof onLoad === 'function') {
            onLoad(mapInstance);
            mapInstance.setOptions({
                ...mapOptions, mapTypeControlOptions: {
                    // style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: window.google.maps.ControlPosition.TOP_RIGHT,
                },
            })
        }
    }

    return isLoaded && Boolean(project._id) && <StyledGoogleMap
        id='example-map'
        mapContainerClassName={'map'}
        options={mapOptions}
        onLoad={onMapLoaded}
        onCenterChanged={onCenterChanged}
        onBoundsChanged={onBoundsChanged}
        {...props}>
        {children}
    </StyledGoogleMap>;
}

export default GoogleMap;