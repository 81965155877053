import React, {useContext, useState} from 'react';

const DialogAlertContext = React.createContext(null);
const DEFAULT_ALERT = {message: "", error: false};

export function DialogAlertProvider({children}) {
    const [alert,setAlert] = useState(DEFAULT_ALERT);
    return <DialogAlertContext.Provider value={[alert,setAlert]}>
        {children}
    </DialogAlertContext.Provider>
}

export function useDialogAlert() {
    return useContext(DialogAlertContext);
}