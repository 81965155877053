import React from 'react';
import PropTypes from "prop-types";
import SubDropdown from "./SubDropdown";
import styled from 'styled-components';

const Li = styled.li`
    cursor: pointer;
`;
function DropdownItem({children, onClick, title, icon}) {
    // eslint-disable-next-line
    return <Li className={children && 'has-child'}><a onClick={onClick}>{icon}{title}</a>
            {children  && <SubDropdown>
                {children}
            </SubDropdown>}
        </Li>;
}

DropdownItem.propTypes = {
    title:PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]).isRequired,
    icon:PropTypes.element,
    onClick:PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
    ])
};

export default DropdownItem;