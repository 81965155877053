import {useEffect, useState} from 'react';
import {extractAddressFromGeocoder, locationToAddress} from "../controller/mapper";

function useAddress(location) {
    const [address, setAddress] = useState('');
    const [tryAgain, setTryAgain] = useState(0);
    useEffect(() => {
        if (address !== '') return;
        let timeoutId;
        // eslint-disable-next-line
        locationToAddress(location).then((results) => {
            setAddress(extractAddressFromGeocoder(results));
        }).catch(status => {
            console.error('address was not being taken', status);
            console.log({statusType: typeof  status, isError: status instanceof Error});
            if (['OVER_QUERY_LIMIT', 'ERROR', 'UNKNOWN_ERROR'].includes(status)) {
                timeoutId = setTimeout(() => {
                    setTryAgain(tryAgain + 1);
                }, Math.floor(Math.random() * 1000));
            }

        });
        return () => {
            clearTimeout(timeoutId);
        }
    }, [location.lat, location.lng, tryAgain, address]);
    return address;
}

export default useAddress;