import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import useProject from "../../Hooks/useProject";
import Modal from "react-bootstrap/Modal";
import BackButton from "../StyledComponents/BackButton";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Api from "../../controller/ApiManager/index";
import {setProject} from "../../store/actionCreators/general";
import SliderInput from "../Common/Inputs/SliderInput";
import Alert from "react-bootstrap/Alert";
import Constants from '../../controller/Constants'
import {addToast} from "../../store/actionCreators/general";

const ModalContent = styled.div`
        width:25vw;
`;
const SubmitButton = styled(Button)`
    &.btn[type=submit]{
      margin-top: 20px !important;
    }
`;
const H3 = styled.h3`
     font-weight: lighter;
`;
const Span = styled.span`
    font-weight: normal;
`;
const StyledLabel = styled(Form.Label)`
  &.form-label{
        font-size: 12px !important;
  }
`;

const DEFAULT_ALERT = {
    message: '',
    error: false,
};

const DEFAULT_INPUTS = {
    correctionFactor: '',
    minRssi: '',
    minCoverage: ''
};

function ParametersU({t, onBack, addToast, setProject, ...props}) {
    const [inputs, setInputs] = useState(DEFAULT_INPUTS);
    const [alert, setAlert] = useState(DEFAULT_ALERT);
    const project = useProject();
    const parameters = project.parameters;
    const projectParamsString = String(parameters.correctionFactor) + String(parameters.minCoverage) + String(parameters.binDiameter) + String(parameters.percentile) + String(parameters.minRssi);
    useEffect(() => {
        const {correctionFactor, binDiameter, percentile, minRssi, minCoverage} = parameters;
        const showEmptyStringIfEmpty = val => isNaN(val) ? '' : val;
        setInputs({
            correctionFactor: showEmptyStringIfEmpty(correctionFactor),
            binDiameter,
            percentile,
            minRssi: showEmptyStringIfEmpty(minRssi),
            minCoverage: showEmptyStringIfEmpty(minCoverage)
        })
    }, [projectParamsString]);

    async function handleSubmit(ev) {
        let oldProject = JSON.parse(JSON.stringify(project));
        props.onFetchStart();
        try {
            ev.preventDefault();
            const newProject = {...project, parameters: {...project.parameters, ...inputs}};
            setProject(newProject);
            const savedProject = await Api.Project.editProject(newProject);
            const populatedProject = await Api.Project.getProject(savedProject._id, {binsPopulated: true});
            setProject(populatedProject);
            setAlert({message: `project ${savedProject.name} parameters updated!`});
        } catch (error) {
            console.error(error);
            setAlert('There Was Error when Updating Site');
            setProject(oldProject);
        } finally {
            props.onFetchEnd()
        }
    }


    return (
        <ModalContent>
            <Modal.Header closeButton>
                <div className="section-title">
                    <h2>{t('Parameters_Title')}</h2>
                    <H3>{t('Project_Name')}: <Span>
                        {project.name.split(' ').map(word => word.slice(0, 1).toUpperCase() + word.slice(1)).join(' ')}
                        </Span>
                    </H3>
                    {typeof onBack === 'function' &&
                    <BackButton className='btn btn-outline-light btn-rounded' onClick={onBack}/>}
                </div>
            </Modal.Header>
            <Modal.Body>
                {alert.message && <Alert dismissible onClose={() => {
                    setAlert(DEFAULT_ALERT)
                }} variant={alert.error ? 'danger' : 'success'}>{alert.message}</Alert>}
                <Form className="form inputs-underline parameters-form" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <Form.Group>
                                <StyledLabel htmlFor="project_name">{t('Minimum_coverage')}</StyledLabel>
                                <SliderInput
                                    disabled={Constants.STAGE === 'prod'}
                                    onChange={num => {
                                        setInputs({...inputs, minCoverage: num})
                                    }}
                                    value={inputs.minCoverage}
                                    step={1}
                                    min={0}
                                    max={100}
                                    placeholder={t('Minimum_Coverage_placeholder')}
                                    units={t('_%')}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <Form.Group>
                                <StyledLabel htmlFor="project_name">{t('Minimum_Rssi')}</StyledLabel>
                                <SliderInput
                                    onChange={num => {
                                        setInputs({...inputs, minRssi: num})
                                    }}
                                    value={inputs.minRssi}
                                    step={1}
                                    min={-150}
                                    max={-50}
                                    placeholder={t('Minimum_Rssi_placeholder')}
                                    units={t('_dBm')}
                                />

                            </Form.Group>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <Form.Group>
                                <StyledLabel htmlFor="project_name">{t('Gain_Factor')}</StyledLabel>
                                <SliderInput
                                    onChange={num => {
                                        setInputs({...inputs, correctionFactor: num})
                                    }}
                                    value={inputs.correctionFactor}
                                    step={1}
                                    min={-50}
                                    max={50}
                                    placeholder={t('EiRP_placeholder')}
                                    units={t('_dBm')}

                                />
                            </Form.Group>
                        </div>
                    </div>
                    <div className='col-sm-12'>
                    </div>
                    <Form.Group className="center">
                        <SubmitButton type={'submit'} variant={'primary'}
                                      className="width-100">{t('Update_Project_Parameters')}</SubmitButton>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </ModalContent>
    )
}

const Parameters = withTranslation()(ParametersU);

function mapStateToProps(reduxState) {
    return {}
}

export default connect(mapStateToProps, {addToast, setProject})(Parameters);