import React from 'react';
import PropTypes from "prop-types";
import styled from "styled-components";
const Li = styled.li`
  &:not(:first-of-type){
    margin-left: 10px;
  }
`;
function NavbarDropdown({children,title,onClick}){
    // eslint-disable-next-line
    return <Li className={"has-child " + (typeof onClick ==='function'?'clickable active ':'')}>
        <a href={'#'} onClick={onClick}>{title}</a>
        <div className="wrapper">
            <div id="nav-listing" className="nav-wrapper">
                <ul>
                    {children}
                </ul>
            </div>
        </div>
    </Li>
}

NavbarDropdown.propTypes = {
    title:PropTypes.string.isRequired,
    onClick:PropTypes.func,
    children:PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
    ]).isRequired
};
export default NavbarDropdown;