import {useEffect, useRef, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React from "react";
import styled from "styled-components";
import ModalDialog from "react-bootstrap/ModalDialog";
import {useTranslation} from "react-i18next";
import useDialogDynamicPositon from "../../Hooks/useDialogDynamicPosition";
import {getSiteSectors, sortSmartTypeString} from "../../controller/dataOperations";
import {useDisplayedAntennasActions} from "../../Hooks/displayedSectors";
import Tooltip from "../Common/Tooltip";
import ButtonsSelect from "../Common/Buttons/ButtonsSelect";
import Constants from "../../controller/Constants";
import SectorsDialogBody from "./SectorsDialogBody";
import {getSectorLabel} from "../../controller/mapper";
import {LocalDisplayedAntennasManager as LDAM, DisplayedAntennasManager as DAM} from "../../controller/models/classes"
const {siteModes} = Constants;

const StyledModalDialog = styled(ModalDialog)`
  cursor: default;
  position: absolute !important;
  ${(props) => (props.aboveProp ? `bottom: 83px` : `top: -12px`)};
  left: -81px;
  z-index: 10;
  button.close {
    span[aria-hidden] {
      position: static;
      top: unset;
      right: unset;
      transform: none;
    }
  }
 
  button.close:focus {
    outline: none;
  }
  .modal-content {
    width: 175px;
  }
  .select-button-group  {
    width:100%;
    margin-left:5px;
    margin-right:10px;
    display:flex;
    justify-content: stretch;
    .select-button ,.select-button:active {
      flex-grow: 1;
      padding: 2px 10px;
    }
  }
  .modal-header .close{
      padding: 0px;
      margin: 0px;
  }
`;

const EmptyDiv = styled("div")`
  width: 35px;
`;

function getAntennaModels(sectors, sectorActive) {
     return sectors.find((item) => item._id === sectorActive)?.antennaModels.sort(sortSmartTypeString);
}

//todo: refactor this component to use displayed sectors instead of origin sectors from project.
function SectorsDialog({site, displayed: dialog, onClose: handleCloseDialog}) {
    const allSectors = getSiteSectors(site); //todo: new refactor - site.getSectors()
    const {displayedAntennas, updateDisplayedAntennas} = useDisplayedAntennasActions(); 
    const displayedAntennasInSite = displayedAntennas.filter(antenna => antenna.siteId === site._id); // here or in the static class?
    const initLocalDisplayedAntennas = LDAM.fromDisplayedAntennas(displayedAntennasInSite);  
    const [localDisplayedAntennas, setLocalDisplayedAntennas] = useState(initLocalDisplayedAntennas);
    const initMode = site.preDesign.sectors.length === 0 ? siteModes.postdesign : siteModes.predesign; 
    //todo: new refactor - const initMode = site.isPreDesignEmpty() ? siteModes.postdesign : siteModes.predesign; 
    const [mode, setMode] = useState(initMode);
    const dialogRef = useRef(null);
    const {t} = useTranslation();
    const above = useDialogDynamicPositon(dialogRef, dialog);
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        const displayedAntennasInSite = displayedAntennas.filter(antenna => antenna.siteId === site._id); // Should this filter be inside the static class?
        let newLocalDisplayedAntennas = LDAM.fromDisplayedAntennas(displayedAntennasInSite);
        newLocalDisplayedAntennas = LDAM.setActiveById(newLocalDisplayedAntennas, getActiveSectorId());   
        setLocalDisplayedAntennas(newLocalDisplayedAntennas);
    }, [displayedAntennas]);

    function onSectorActive(sectorId) {
         setLocalDisplayedAntennas(LDAM.setActiveById(localDisplayedAntennas, sectorId));
     }

    function onModelActive(antennaModel) {// onSelectAntennaModel? // onChangeAntennaModel?
        setLocalDisplayedAntennas(LDAM.updateActiveAntennaModel(localDisplayedAntennas, antennaModel));
    }

    function getActiveSectorId() {
        const id = LDAM.getActiveSectorId(localDisplayedAntennas);
        if(localDisplayedAntennas.length < 1)
           return 0;
        if(!id){ // no antenna is active
            setLocalDisplayedAntennas(LDAM.setFirstSectorActive(localDisplayedAntennas));
            return localDisplayedAntennas[0].sectorId; 
        }
        return id;
    }

    function handleTooltip(key) {
        const show = key === "antenna types";
        setShowTooltip(show);
    }

    function onSectorCheck(sectorId) { // onCheckSector ???? onAnntenaCheck
        setLocalDisplayedAntennas(LDAM.toggleDisplayById(localDisplayedAntennas, sectorId));
    }

    function isSectorChecked(sectorId) {
        return Boolean(localDisplayedAntennas.find(antenna => antenna.sectorId === sectorId)?.display); // Should this filter be inside the static class?
    }

    function isTypeChecked(type) { // isAntennaModelTypeChecked? // isAntennaModelChecked?
        return Boolean(localDisplayedAntennas.find(antenna => antenna.active)?.antennaModel === type); // Should this filter be inside the static class?
    }

    function applyChanges() {
        const localSelected = localDisplayedAntennas.filter(antenna => antenna.display);
        const displayedAntennasNotInSite = displayedAntennas.filter(antenna => antenna.siteId !== site._id && antenna.display); // Should this filter be inside the static class?
        const displayIds = [...localSelected, ...displayedAntennasNotInSite].map(antenna => antenna.sectorId);
        updateDisplayedAntennas(DAM.getUpdatedAntennas(displayedAntennas, localSelected, displayIds));
    }

    function isActiveSelected() {  // isActiveDisplayed? // isActiveChecked?
        return Boolean(localDisplayedAntennas.find(antenna => antenna.active)?.display); // Should this filter be inside the static class?
    }

    function createSelectSectorLabel(sector) {
        const localDisplayedAntenna = localDisplayedAntennas.find(antenna=>antenna.sectorId == sector._id); // getSectorById   // Should this filter be inside the static class?
        const convesionPostfix = localDisplayedAntenna?.conversion?.to ? ` (${localDisplayedAntenna.conversion.to.toUpperCase()})` : '';
        return getSectorLabel(sector) + convesionPostfix;
    }
    return dialog && <StyledModalDialog
        aboveProp={above}
        size={"md"}
        scrollable={true}
        ref={dialogRef}>
        {showTooltip && <Tooltip
            text={getSectorLabel(allSectors.find(sector => sector._id === getActiveSectorId()))}
            placement="right"
            visible
            style={{top: 44, left: 184}}>
        </Tooltip>}
        <Modal.Header
            className={'modal-header-sectors'}
            style={{padding: 8}}
            closeButton
            onHide={()=>{setShowTooltip(false); handleCloseDialog()}}>
            <ButtonsSelect
                values={[
                    {
                        value: 'Predesign',
                        label: t('pre_'),
                        checked: mode === 'Predesign',
                        disabled: site.preDesign.sectors.length === 0 // site.isPreDesignEmpty()
                    },
                    {
                        value: 'Postdesign',
                        label: t('post_'),
                        checked: mode === 'Postdesign',
                        disabled: site.postDesign.sectors.length === 0 // site.isPostDesignEmpty()
                    }]}
                onSelect={(item) => setMode(item.value)}>
            </ButtonsSelect>
        </Modal.Header>
        {localDisplayedAntennas.length > 0 && <SectorsDialogBody
            notEmpty={allSectors.length > 0 } // site.isSectorsEmpty()
            handleTooltip={handleTooltip}
            typesOptions={getAntennaModels(allSectors, getActiveSectorId()).map((item) => ({
                value: item,
                label: t(`${item}_hd`),
                checked: isTypeChecked(item),
            }))}
            sectorsOptions={allSectors.filter(sector => sector.siteMode === mode).map((sector) => ({
                value: sector._id,
                label:createSelectSectorLabel(sector),
                checked: isSectorChecked(sector._id),
            }))}
            active={getActiveSectorId()}
            onSectorActive={onSectorActive}
            onSelectSector={onSectorCheck}
            disabled={!isActiveSelected()}
            onModelActive={onModelActive}/>}
        <Modal.Footer style={{padding: "10px 16px"}}>
            <Button onClick={handleCloseDialog} variant="secondary"> Close </Button>
            <EmptyDiv/>
            <Button onClick={applyChanges} variant="primary"> Apply </Button>
        </Modal.Footer>
    </StyledModalDialog>;
}

export default SectorsDialog;