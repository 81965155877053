import {
    ADD_PROJECT,
    MAP_LOADED,
    RESET_PROJECTS,
    SET_COVERAGE,
    SET_DISPLAYED_ANTENNAS,
    SET_HIGHLIGHT_OPACITY,
    SET_LINES,
    SET_LOCATION_STATS,
    SET_MAP_STATE,
    SET_MAP_TYPE,
    SET_MAP_ZOOM,
    SET_PREDICTION_STATE,
    SET_PROJECTS,
    SET_POLYGON_PATH,
    SET_TOTAL_COST,
    UPDATE_DISPLAYED_ANTENNAS
} from "../actionTypes";
import Constants from "../../controller/Constants";
import {DEFAULT_LOCATION_STATS} from "../defaultValues/map";
import {updateDisplayedAntennas} from "../actions/map";
import {DisplayedAntennasList} from "../../controller/models/classes"

export const DEFAULT_STATE = {
    mapType: Constants.mapTypes.BEST_SERVER,
    mapState: Constants.mapStates.SITES_COVERAGE,
    lines: false,
    coverage: 0,
    totalCost: 0,
    loaded: false,
    projects: [],
    currentProject: {},//populated sites.
    displayedAntennas: [], //new DisplayedAntennasList([]), ? ? ? ? ? ? ? ? ? ? ?  ? ? ? ? ? ? ? ?
    mapZoom: 0,
    highlightOpacity: 0,
    predictionState: false,
    polygonPath: [" "],
    locationStats: DEFAULT_LOCATION_STATS,
};

const mapReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SET_LINES:
            return {...state, lines: action.linesFlag};
        case SET_COVERAGE:
            return {...state, coverage: Number(action.coverage).toFixed(2)};
        case SET_TOTAL_COST:
            return {...state, totalCost: Number(action.totalCost).toFixed(0)};
        case MAP_LOADED:
            return {...state, loaded: action.loaded != false};
        case SET_POLYGON_PATH:
            return {...state, polygonPath: action.polygonPath}; 
        case SET_PROJECTS:
            return {...state, projects: action.projects};
        case RESET_PROJECTS:
            return {...state, projects: []};
        case SET_MAP_TYPE:
            return {...state, mapType: action.mapType};
        case SET_MAP_STATE:
            return {...state, mapState: action.mapState};
        case SET_MAP_ZOOM:
            return {...state, mapZoom: action.mapZoom};
        case ADD_PROJECT:
            return {...state, projects: [...state.projects, action.project]};   
        case SET_DISPLAYED_ANTENNAS:
            return {...state, displayedAntennas: action.displayedAntennas}; 
        case UPDATE_DISPLAYED_ANTENNAS:
            return {...state, displayedAntennas: updateDisplayedAntennas(state, action.displayedAntennas)};
        case SET_LOCATION_STATS:
            return {...state, locationStats: action.locationStats};
        case SET_HIGHLIGHT_OPACITY:
            return {...state, highlightOpacity: action.highlightOpacity};
        case SET_PREDICTION_STATE:
            return {...state, predictionState: action.predictionState};
        default:
            return state;
    }
};

export default mapReducer;