import {useDispatch} from "react-redux";
import {addSectorToSiteAC, addSiteToProjectAC} from "../store/actionCreators/general";

export function useGlobalStateOperations() {
    const dispatch = useDispatch();
    const addSectorToSite = (siteId, sector) => {
        dispatch(addSectorToSiteAC(siteId, sector));
    };

    const addSiteToProject = (site) => {
        dispatch(addSiteToProjectAC(site));
    };


    return {addSectorToSite, addSiteToProject};
}