import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {DELETE_TOAST} from "../store/actionTypes";
import AlertToast from "./ToastAlert";

const Div = styled.div`
  position: fixed;
  bottom: 0;
  right: 50px;
  z-index: 100;
  padding:20px;
`;
function AlertGenerator({currentAlerts, dispatch}) {
    function closeToast(id) {
        dispatch({
            type:DELETE_TOAST,
            toastId:id,
        })
    }
    return (currentAlerts.length > 0 ? <Div className={'alert-generator'}>
        {currentAlerts.map(toastDetails=>(<AlertToast
            key={toastDetails.id}
            {...toastDetails}
            onClose={closeToast}
        />))}
            </Div>:<div className={'alert-gene-0'}></div>);
}

function MapStateToProps(reduxState) {
    return {currentAlerts: reduxState.general.toasts};
}

export default connect(MapStateToProps)(AlertGenerator);