import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import styled from 'styled-components';
import Validator from "../../controller/Validator";
import FileInput from "../Common/Inputs/FileInput";
import DeleteButton from "../Common/Buttons/DeleteButton";
import ButtonsSelect from "../Common/Buttons/ButtonsSelect";
import Constants from "../../controller/Constants";
import {errors} from "../../controller/Constants";
import PricesPopover from "./PricesPopover";
import { technologies } from "../../controller/models/enums"

const { smartTypeStrings } = Constants;
const Small = styled.small`
    margin-left: ${props => props.margin ? props.margin + 'px' : '10px'};
    opacity: 0.8;
`;

const DeleteCol = styled(Col)`
    height: auto;
    text-align: end;
`;

function numberize(newInputs) {// cast fields that can be casted to numbers.
    const numberized = Object.keys(newInputs).reduce((acc, key) => ({
        ...acc,
        [key]: Validator.parseToNumberIfCan(newInputs[key])
    }), {});
    console.log({ notNumbrtized: newInputs, numberized });
    return numberized;
}

function formatDate(dateString) {
    const fileDate = new Date(Number(dateString));
    const dateFormatted = fileDate.toISOString().slice(0, 10) + ' ' + fileDate.toISOString().slice(11, 16);
    return dateFormatted.replace(/-/g, '.');
}

const fields = [
    { field: 'height', type: 'number' },
    { field: 'cost', type: 'number' },
    { field: 'azimuth', type: 'number' },
    { field: 'number', type: 'number' },
    { field: 'technology', type: 'text' },
    { field: 'antennaModel', type: 'text' },
    { field: 'electricTilt', type: 'number' },
    { field: 'mechanicTilt', type: 'number' },
    { field: 'txLoss', type: 'number' },
    { field: 'txPower', type: 'number' },
    { field: 'output', type: 'number' },
    { field: 'frequency', type: 'number' },
    { field: 'antennaConfig', type: 'text' },
    { field: 'EiRP', type: 'number' },
];

function SectorInputs({ sector, onSectorChange, title, onDelete, button }) {
    const [sectorState, setSectorState] = useState(sector);
    const {t} = useTranslation();

    useEffect(() => {
        setSectorState(sector);
    }, [sector]);

    const updateInputs = useCallback((newInputs) => {
        setSectorState(newInputs);
        const numberedInputs = numberize(newInputs);
        onSectorChange(numberedInputs);
    }, [setSectorState, onSectorChange]);

    const fileChange = useCallback((file) => {
        const newInputs = { ...sectorState, file };
        updateInputs(newInputs);
    }, [sectorState, updateInputs]);

    const deleteSite = useCallback(() => {
        onDelete(sector._id);
    }, [onDelete, sector]);

    const changeField = useCallback((ev) => {
        const field = ev.target.name;
        let value;
        if (field === 'azimuth') {
            value = Boolean(ev.target.value) ? ev.target.value : undefined;
        }
        else if (field === 'technology') {
            value = !(ev.target.value === "") ? ev.target.value : null;
        }
        else {
            value = ev.target.value;
        }
        updateInputs({ ...sectorState, [field]: value });
    }, [sectorState, updateInputs]);

    const smartTypeChange = useCallback((obj) => {
        if (obj.value === smartTypeStrings[0]) return;
        let antennaModels = sectorState.antennaModels.slice();
        if (antennaModels.includes(obj.value))
            antennaModels = antennaModels.filter(type => type !== obj.value);
        else
            antennaModels = [...antennaModels, obj.value].sort((a, b) => a - b);
        if (!antennaModels.includes('TYPE_1X1')) throw Error(errors.NO_1X1_MODEL);
        updateInputs({ ...sectorState, antennaModels })
    }, [sectorState, updateInputs]);

    const antennaPricesChange = useCallback((antennaPrices) => {
        console.log('SectorInputs:', { ...antennaPrices, heightState: sectorState.antennaPrices });
        updateInputs({ ...sectorState, antennaPrices });
    }, [sectorState, updateInputs]);

    return <Form>
        <Form.Row>
            {
                fields.map(({ field, type }) => (
                    <Col sm={4} key={field}>
                        <Form.Label column={''}>{t(field.capitalize() + '_Input')}</Form.Label>
                        {!(field == 'technology') ?
                            <Form.Control
                                value={sectorState[field] === null ? '' : sectorState[field]}
                                onChange={changeField}
                                name={field === null ? '' : field}
                                placeholder={t('Sector_Input_' + field.capitalize({ single: false }))}
                                type={type}
                            />
                            :
                            <Form.Control
                                as={'select'}
                                // value={type}
                                value={sectorState[field] === null ? '' : sectorState[field]}
                                onChange={changeField}
                                name={field === null ? '' : field}
                                placeholder={t('Sector_Input_' + field.capitalize({ single: false }))}
                                type={type}
                                className={'select-sf'}
                            >
                            <option value="" selected>Technology</option>
                            {technologies.map((tech => (tech) ? (<option value={tech}>{tech}</option>) : (<option> {null}</option>)))}
                            </Form.Control>
                        }
                    </Col>)).insert(2, <DeleteCol sm={4} key={'delete-button'}>{/*at third index (2) insert this col*/}
                        {typeof onDelete === 'function' &&
                            <DeleteButton label={t('Delete_height')} onClick={deleteSite} />}
                    </DeleteCol>)
            }
        </Form.Row>
        <Form.Row>
            <Form.Label column={2} sm={12}>
                {t('Smart_Antenna_Types')}
            </Form.Label>
            <Col sm={10}>
                <ButtonsSelect
                    values={Object.keys(Constants.smartTypes).map((smartTypeStr) => ({
                        value: smartTypeStr,
                        label: t(smartTypeStr),
                        checked: sectorState.antennaModels.includes(smartTypeStr)
                    }))}
                    onSelect={smartTypeChange}
                />
            </Col>
            <Col sm={2}>
                <PricesPopover
                    onChange={antennaPricesChange}
                    selectedAntennaModels={smartTypeStrings}
                    antennaPrices={sectorState.antennaPrices}
                />
            </Col>
        </Form.Row>
        <Form.Row>
            <Form.Label sm={12} column={1}>
                {t('Drive_Test_File')}
                {sector && sector.driveTestFile && sector.driveTestFile.bucket &&
                    <Small>{t('stored_file')}: <i className="fa fa-file" aria-hidden="true" />
                        {sector.driveTestFile.bucket.split('/').slice(-1)}
                    </Small>}
                {sector && sector.driveTestFile && sector.driveTestFile.fileId && sector.driveTestFile.bucket &&
                    <Small margin={25}>{t('last_updated')}: <i className="fa fa-calendar-o" aria-hidden="true" />
                        {formatDate(sector.driveTestFile.fileId)}
                    </Small>}
            </Form.Label>
            <Col sm={button ? 9 : 12}>
                <FileInput
                    disable={!Boolean(sectorState._id)}
                    file={sectorState.file}
                    onFileChange={fileChange}
                    formats={['.mtd', '.csv']}
                    placeHolder='Click or drag drive test file here'
                    disablePlaceHolder='Please Add Height First' 
                />
            </Col>
            {button && <Col sm={3}>
                {button}
            </Col>}
        </Form.Row>
    </Form>
}

SectorInputs.propTypes = {
    sector: PropTypes.object.isRequired,
    title: PropTypes.string,
    onFileChange: PropTypes.func,
    button: PropTypes.element,
    onSectorChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func
};


export default SectorInputs;
