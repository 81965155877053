import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useProjectParams} from "./useProject";
import Constants from "../controller/Constants";
import {mapLayerValues} from "../controller/Constants";
import {useUserGroups} from "./common";
import {technologies} from '../controller/models/enums'
export function useMapLayerInputs() {
    const {t} = useTranslation();
    const userGroups = useUserGroups();
    const projectParams = useProjectParams();
    const {mapLayer, technology, frequencyBand, channelBW, rssiThreshold, downUpLinkRatio, antennaPolarization, devParams} = projectParams;
    const {samplesReliability, binResolution, binReliability, polarizationLoss, systemGain, minCoverage} = devParams;
    const isBitRate = ['Bit Rate - Coverage Optimized', 'Bit Rate - Capacity Optimized'].includes(mapLayer);
    const [DBM,MBPS,MHZ,PERCENT,METERS]=['dbm','Mbps','Mhz','%','m'].map(w=>' '+w);
    let defaultInputs = [
        {
            label: t('Map_Layer_sf'),
            inputType: 'select',
            value: mapLayer,
            key: 'mapLayer',
            selectList: mapLayerValues
        },
        {
            label: t('Technology_sf'),
            inputType: 'select',
            value: technology,
            key: 'technology',
            selectList: technologies,
        },
        {
            label: t('Frequency_Band_sf'),
            inputType: 'select',
            value: frequencyBand,
            key: 'frequencyBand',
            selectList: [3600, 2600, 2100, 1800],
            units: MHZ,
        },
        {
            label: t('Channel_BW_sf'),
            inputType: 'select',
            value: channelBW,
            key: 'channelBW',
            selectList: [20, 40, 60, 100],
            disabledOptions: [40, 100],
            units: MHZ,
        },
        {
            label: isBitRate ? t('Bit_Rate_Threshold_sf') : t('Rssi_Threshold_sf'),
            inputType: 'number',
            value: rssiThreshold,
            key: 'rssiThreshold',
            units:isBitRate?MBPS: DBM,
            // selectList: isBitRate ? [0, 2.3, 6.8, 10, 18.6, 29.4, 30, 54, 55.8, 88.3, 162] : undefined,
        },
        {
            disabled: true,
            label: t('DL_UL_Ratio_sf'),
            inputType: 'number',
            value: downUpLinkRatio,
            key: 'downUpLinkRatio',
        },
        {
            label: t('Antenna_Polarization_sf'),
            inputType: 'select',
            value: antennaPolarization,
            key: 'antennaPolarization',
            selectList: ['Vertical', 'Horizontal', 'Circular', 'Dual Slant 45deg'],
            disabledOptions: ['Horizontal', 'Circular']
        },
        {
            label: t('minCoverage'),
            inputType: 'number',
            value: minCoverage,
            key: 'devParams.minCoverage',
            units:PERCENT,
        },
        // {
        //     // disabled: true,
        //     label: t('polarizationLoss'),
        //     inputType: 'text',
        //     value: polarizationLoss,
        //     key: 'devParams.polarizationLoss',
        // },
        {
            label: t('systemGain'),
            inputType: 'number',
            value: systemGain,
            key: 'devParams.systemGain',
            units: DBM,
        }, {
            label: t('binReliability'),
            inputType: 'number',
            value: binReliability,
            key: 'devParams.binReliability',
            units: PERCENT,
        }, {
            label: t('binResolution'),
            inputType: 'number',
            value: binResolution,
            key: 'devParams.binResolution',
            units: METERS,
        }, {
            label: t('samplesReliability'),
            inputType: 'number',
            value: samplesReliability,
            key: 'devParams.samplesReliability',
            units: PERCENT,
        },
    ];
    defaultInputs = defaultInputs.slice(0, defaultInputs.length - (userGroups?.includes(Constants.userGroups.ADMIN) ? 0 : 6));
    const [inputs, setInputs] = useState(defaultInputs);
    useEffect(() => {
        setInputs(defaultInputs);
        // eslint-disable-next-line
    }, [mapLayer, technology, frequencyBand, channelBW, rssiThreshold, downUpLinkRatio, antennaPolarization,JSON.stringify(defaultInputs)]);
    return {defaultInputs, inputs, setInputs};
}