import React, {useRef, useState} from 'react';
import {GoogleMap, Marker, InfoWindow} from '@react-google-maps/api'
import styled from "styled-components";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import PlacesAutoComplete from "../GoogleAutoComplete";

const StyledGoogleMap = styled(GoogleMap)`
  height: 400px;
  width: 300px;
`;
const StyledLoadingDiv = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  .fa-spinner{
    position:absolute;
    top: calc(50% - 31px);
    left: calc(50% - 48px);
    transform: translate(-50%,50%);
}`;

function LocationInput({center, onLocationChange, staticSites}) {
    const {mapLoaded} = useSelector(mapStateToProps);
    const [rightClick, setRightClick] = useState({lat: null, lng: null});
    const [marker, setMarker] = useState(null);
    const {t} = useTranslation();
    const mapOptions = {
        clickableIcons: false,
        disableDefaultUI: true,
    };
    const autoCompleteRef = useRef(null);
    const currentMap = useRef(null);

    function placeMarker() {
        onLocationChange(rightClick);
        setRightClick({lat: null, lng: null})
    }

    function onPlaceChanged() {
        const place = autoCompleteRef.current.getPlace();
        const lat = Number(place.geometry.location.lat());
        const lng = Number(place.geometry.location.lng());
        const location = {lat, lng};
        if (currentMap.current) {
            currentMap.current.setCenter(location);
        }
    }


    return mapLoaded ?
        <StyledGoogleMap
            id="simple-map-example"
            mapContainerStyle={{
                height: "100%",
                width: "100%"
            }}
            zoom={10}
            center={center}
            options={mapOptions}
            onRightClick={(ev) => {
                const [lat, lng] = [(ev.latLng.lat()), (ev.latLng.lng())].map(loc => Number(loc));
                // populate yor box/field with lat, lng
                setRightClick({lat, lng});
            }}
            onLoad={map => {
                currentMap.current = map;
            }}
        >
            <PlacesAutoComplete
                defaultValue={''}
                onPlaceChanged={onPlaceChanged}
                ref={autoCompleteRef}
                placeholder={t('Choose_Place')}
                inputStyle={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: `240px`,
                    height: `32px`,
                    padding: `0 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                    position: "absolute",
                    left: 0,
                }}
            />
            <Marker
                onLoad={marker => {
                    setMarker(marker);
                }}
                position={center}
                draggable={true}
                onDragEnd={() => {
                    const locationObject = {lat: Number(marker.position.lat()), lng: Number(marker.position.lng())};
                    onLocationChange(locationObject)
                }}

            />
            {rightClick.lat && rightClick.lng && <InfoWindow
                position={rightClick}
                onCloseClick={() => {
                    setRightClick({lat: null, lng: null});
                }}
            >
                <div>
                    <button onClick={placeMarker}> {t('place_marker')} </button>
                </div>
            </InfoWindow>}
        </StyledGoogleMap> :
        <StyledLoadingDiv>
            <i className="fa fa-spinner fa-pulse fa-5x fa-fw"/>
            <span className="sr-only">Loading...</span>
        </StyledLoadingDiv>;
}

function mapStateToProps(reduxState) {
    return {mapLoaded: reduxState.map.loaded};
}

export default LocationInput;