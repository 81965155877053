import rootReducer from './reducers';
import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
export function configureStore() {
    const composeFunc = (
        (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
            ?
            compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
            :
            compose(applyMiddleware(thunk))
    );
    try{
       return createStore(
        rootReducer,
        composeFunc
        ); 
    }catch(e){
        return createStore(rootReducer,compose(applyMiddleware(thunk)));
    }
    
}