import React, {useCallback, useEffect, useRef, useState} from "react";
import Form from "react-bootstrap/Form";
import * as PropTypes from "prop-types";

function InputWithUnits({onChange, value, keyProp: key, disabled, units, type}) {
    const valueWithUnits = value + (units ?? '');
    const [inputValue, setInputValue] = useState(valueWithUnits);
    const [focused, setFocused] = useState(false);
    const inputRef = useRef(null);
    useEffect(() => {
        if (!focused) {
            setInputValue(valueWithUnits);
        } else {
            setInputValue(value);
        }
    }, [value]);
    
    const handleChange = useCallback((ev) => {
        const val = ev.target.value;
        setInputValue(val);
        onChange(val !== '' && type === 'number' ? Number(val) : val, key);
    }, [inputValue, onChange, setInputValue, type, key]);
    
    const handleFocus = useCallback((ev) => {
        const elem = ev.target;
        setTimeout(() => {
            // this is a workaround to being eble to add
            // units and remove them on focus to change the input.
            inputRef.current.type = 'text';
            elem.selectionStart = elem.selectionEnd = elem.value.length;
            inputRef.current.type = type;
        }, 0);
        setFocused(true);
        setInputValue(value);
    }, [setInputValue, focused, value]);   
    
    const handleBlur = useCallback(() => {
        setFocused(false);
        setInputValue(valueWithUnits);
    }, [focused, setInputValue, valueWithUnits]);


    return <Form.Control
        // autocomplete="off"
        // controllId={key + '-fc'}
        ref={inputRef}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={{textAlign: 'right', zIndex: 1000}}
        type={focused ? type : 'text'}
        name={'key'}
        disabled={disabled || false}
        onChange={handleChange}
        value={inputValue}
        title={value + (units ?? '')}/>
}

InputWithUnits.defaultProps = {
    type: 'text',
    keyProp: null,
    disabled: false,
    units: ''
};

InputWithUnits.propTypes = {
    type: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    keyProp: PropTypes.any,
    disabled: PropTypes.bool,
    units: PropTypes.string,

};
export default InputWithUnits;