import React, {useEffect, useState} from 'react';
import {OverlayView} from '@react-google-maps/api';
import SectorsDialog from '../Sector/SectorsDialog'
import styled from "styled-components";
import {useDisplayedAntennas} from "../../Hooks/displayedSectors";
import useProject from "../../Hooks/useProject";
import Constants from "../../controller/Constants";
 
// background-image: url("https://flycomm-cover-rest-api-dev-images.s3.eu-west-1.amazonaws.com/target.jpg"); /*    new     */
//     background-position: center;         /*    new     */
//     background-size: cover;            /*    new     */

const MarkerDiv = styled.div`
  &&{
      z-index: 1;
      &.marker .marker-wrapper:before {
        background-color: ${({backgroundColor}) => backgroundColor} !important;
        ${({isProd, imageUrl}) => isProd && imageUrl &&`
          //  background-color: blue !important;
           background-image: url(${imageUrl}) !important;
           background-position: center !important;
           background-size: contain !important;
           height: 32px;
           width: 32px;
           top: 3px;
           left: 3px;
        `}
      }
      &.marker{
        text-align: start;
      }
      .title{
        opacity: 0;
        transition: opacity 400ms ease-in;
        &:hover{
          opacity: 1;
        }
      }
      &.marker .marker-wrapper .pin .image{
         box-shadow: none;
        &::after{
          border-top-color:${({theme}) => theme.components.marker.color};
        }
      }
      &.marker .marker-wrapper .pin{
        border-color:${({theme}) => theme.components.marker.color};
        &::before{
            background-color:${({theme}) => theme.components.marker.color};
            border-color:${({theme}) => theme.components.marker.color};
        }
      }
  }
`;

const Title = styled.h3`
  color: #fff;
  position:relative;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  text-align: center;
  line-height: 30px;
`;
const MarkerImage = styled.div`
&&{
  &.markerImage{
    width: 20px;
    height: 20px;
  }
}
  /* position:relative; */
  /* top:50%;
  left:50%; */
  
`;

function Marker({clusterer, project, site}) {
    const projectId = useProject()._id;
    const projectImageUrl = useProject().sitesImage;
    const [dialog, setDialog] = useState(false);
    const allDisplayedAntennas = useDisplayedAntennas(); 
    const displayedAntennas = allDisplayedAntennas.filter(displayedAntenna => displayedAntenna.siteId === site._id);
    const siteSectors = site.preDesign.sectors.concat(site.postDesign.sectors);
    const relevantDisplayedAntennas = displayedAntennas.filter(displayedAntenna => displayedAntenna.display);

    useEffect(() => {
        setDialog(false);
    }, [projectId]);

    function markerClick(ev) {
        if (siteSectors.length > 0)
            setDialog(!dialog);
    }

    function handleCloseDialog(ev) {
        setDialog(false);
    }

    return <OverlayView
        position={site.location}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
        <div style={{position: 'relative'}}>
            <MarkerDiv
                imageUrl={site?.image || projectImageUrl || Constants.DEFAULT_PROJECT_SITES_IMAGE}
                isProd={process.env.REACT_APP_STAGE === "prod"}
                backgroundColor={relevantDisplayedAntennas.length > 0 ? '#595763' : '#E42324'}
                onClick={markerClick}
                className="marker">
                  <div className="title">{site.displayName}</div>
                <div className="marker-wrapper">
                    {site.checked && <div className="tag"><i className="fa fa-check"/></div>}
                    <div className="pin">
                        <Title className='image'>{process.env.REACT_APP_STAGE === "dev" && relevantDisplayedAntennas.length}</Title>
                    </div>
                </div>
            </MarkerDiv>
            <SectorsDialog
                displayed={dialog}
                onClose={handleCloseDialog}
                site={site}
            />
        </div>
    </OverlayView>;
}


export default (Marker);