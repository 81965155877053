import {events, errors} from "../Constants";
import {application} from "../../App";
import EventEmitter from '../EventEmitter'

export async function navigateResponse(res) {
    if (res.ok) {
        const data = await res.json();
        if (data.presignedUrl){
            return await fetch(data.presignedUrl).then(navigateResponse).catch(handleError);
        }
        return data;
    }
    if (Number(res.status) === 401) {
        throw Error(errors.UNAUTHORIZED);
    }
    console.log('Error response: ', res);
    throw Error('there were problem with the server response, status: ' + res.status + '\nErrorMessage: '+ await res.json());
}

export function userNotLoggedIn() {
    const eventName = events.REFRESH_USER_TOKEN;
    if (process.env.REACT_APP_STAGE === 'dev') {
        // window.alert(eventName +' is being dispatched please see logs and add debuggers!');
    }
    const isDispatched = EventEmitter.dispatch(eventName);
    console.log(eventName + (isDispatched ? '' : ' not ') + ' dispatched');

    throw Error(errors.TRY_AGAIN);
}

export function handleError(err) {
    if (err.message === errors.UNAUTHORIZED) {
        return userNotLoggedIn();
    }
    console.error(err);
    throw err;
}

export function fetchAuth(url, options = {headers: {}}) {
    const storeState = application.store.getState();
    return fetch(url, {...options, headers: {...options.headers, 'Authorization': storeState.user.idToken}})
}

