import styled from "styled-components";
import PercentProgress from "./Common/Progress";
import Badge from "react-bootstrap/Badge";

export const StyledProgress = styled(PercentProgress)`
  //max-width: 100px;
  flex-grow: 1;
  margin:5px 10px ;
  width:100px;  
`;
export const Li = styled.li`
  &:not(:first-of-type){
    margin-left: 10px;
  }
`;
export const Div = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-left: 10px;
  padding-top: 2px;
  min-width: 100px;
  width: auto;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
`;
export const A = styled.a`
  margin-left: 10px;
  &.btn-outline-secondary{
      box-shadow: none;
      color:${({theme}) => theme.palette.primary.dark};
      border-color:${({theme}) => theme.palette.primary.dark};
      border-width: 1px;
      &:hover{
         box-shadow: none;
         color:${({theme}) => theme.palette.common.white};
         background-color:${({theme}) => theme.palette.primary.main};
         border-color:${({theme}) => theme.palette.primary.main};
      }     
      &:not(:disabled):not(.disabled):active{
         box-shadow: none;
         background-color:${({theme}) => theme.palette.primary.dark};
         border-color:${({theme}) => theme.palette.primary.dark};
         transform: translateY(0); 
      }    
  }
`;
export const StyledBadge = styled(Badge)`
    &.badge {
      margin:3px 10px 7px 10px ;
      background-color: ${({variant,color,theme})=>Boolean(theme.palette[variant])?theme.palette[variant].main:color};
    }
`;
export const StyledBadgeCost = styled(Badge)`
    margin:3px 10px 7px 10px ;
    cursor:pointer;
`;
export const NavbarHeader = styled.header`
    &#page-header{
        .navigation i.fa{
           color:${({theme})=>theme.palette.secondary.dark};
           opacity: 1;
        }
    }
`;