import React, {useRef} from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import logo1 from '../images/logo/logo-13.png'
import logo2 from '../images/logo/logo-14.png'
import {connect} from "react-redux";
import {SET_CURRENT_DIALOG} from "../store/actionTypes";
import Constants from "../controller/Constants";
import {modals, mapLayers} from "../controller/Constants";
import useProject, {useProjectParams} from "../Hooks/useProject";
import {showCostsTable} from "../store/actionCreators/map";
import useTotalCost from "../Hooks/useTotalCost";
import NavbarDropdown from "./Common/Dropdown/NavbarDropdown";
import DropdownItem from "./Common/Dropdown/DropdownItem";
import {logoutUser} from "../store/actionCreators/user.js";
import {resetProject} from "../store/actionCreators/general";
import {resetProjects, setMapType, setDisplayedSectors} from "../store/actionCreators/map";
import Api from "../controller/ApiManager";
import {A, Div, NavbarHeader, StyledBadge, StyledBadgeCost, StyledProgress} from "./NavbarStyles";
import {getLayerType} from "../controller/mapper";
import {useFetchLoader} from "../Hooks/common";
import {useDisplayedAntennasActions} from "../Hooks/displayedSectors"; 
import FileUploaderBtn from "../components/Common/FileUploaderBtn"
import ImageUploaderBtn from "../components/Common/ImageUploaderBtn"
import ConfigFileManager from "../components/Common/ConfigFileManager"
import FileManager from "../components/Common/FileManager"
import {DisplayedAntennasManager as DAM} from "../controller/models/classes"

import {useProjectState} from "../Hooks/useProject";

const {smartTypeStrings} = Constants;

const isProd = Constants.STAGE === 'prod';

function getMaximumCoverage(project) {
    return project.sites.reduce((acc, site) => {
        if (!(site.preDesign.sectors.length > 0)) {
            return acc;
        }
        const siteId = site._id;
        const height = Math.max(...site.preDesign.sectors.map(height => Number(height.height)));
        return [...acc, {site: siteId, height, type: smartTypeStrings[0]}]
    }, []);
}

function Navbar({
                    dispatch, user, lines, coverage, showCostsTable,
                    setMapType, mapType, logoutUser, projects, resetProject, resetProjects, ...props
                }) {
    const {t} = useTranslation();
    const project = useProject();
    const projectParams = useProjectParams();
    const {rssiThreshold, mapLayer} = projectParams;
    const mapLayerTypeType = getLayerType(mapLayer);
    const totalCost = useTotalCost();
    const showCoverage = mapType !== Constants.mapTypes.C2I;
    const {isAuthenticated} = user;
    const {BIT_RATE_CAPACITY, BIT_RATE_COVERAGE} = mapLayers;
    const isBitRate = [BIT_RATE_CAPACITY, BIT_RATE_COVERAGE].includes(mapLayer);
    const [fetching, setFetchLoader] = useFetchLoader();
    const configFileManagerRef = useRef(null);
    const imageUploaderRef = useRef(null);
    const {displayedAntennas, updateDisplayedAntennas} = useDisplayedAntennasActions();


    function openDialog(dialogName) {
        dispatch({
            type: SET_CURRENT_DIALOG,
            dialog: dialogName
        })
    }

    function openAddProjectDialog(ev) {
        openDialog(modals.ADD_PROJECT)
    }

    function openDisplayCoverage(ev) {
        openDialog(modals.DISPLAY_COVERAGE)
    }

    async function onOptimizeProject(ev) {
        try {
            ev.preventDefault();
            setFetchLoader(true);
            const heightsBest = await Api.optimizeProject(project._id);
            const displayIds = heightsBest.map(antenna => antenna.sectorId);
            const newAntennas = DAM.getUpdatedAntennas(displayedAntennas, heightsBest,displayIds);
            updateDisplayedAntennas(newAntennas);  
            showCostsTable(true);
        } catch (error) {
            console.error(error);
        } finally {
            setFetchLoader(false);
        }
    }

    function showProjects() {
        openDialog(modals.PROJECT_LIST)
    }

    function openEditProjectDialog() {
        openDialog(modals.EDIT_PROJECT)
    }

    function handleCostClick() {
        showCostsTable(true);
    }

    function resetBins() {
        updateDisplayedAntennas([]);
    }

    function setBestSever() {
        setMapType(Constants.mapTypes.BEST_SERVER);
    }

    function setC2I() {
        setMapType(Constants.mapTypes.C2I);
    }

    function clusterProject() {
        openDialog(modals.CLUSTER);
    }

    function openLoginDialog() {
        openDialog(modals.LOGIN);
    }

    function logout() {
        // UserApi.logout(user.user.email).then(console.log);
        resetProject();
        resetProjects();
        logoutUser()
    }

    function onChoseSites() {
        openDialog(modals.CHOSE_2_SITES);
    }

    function onDisplayCoverage() {
        openDisplayCoverage();
    }

    return (
        <NavbarHeader id="page-header">
            <nav>
                <div className="left">
                    <div className="secondary-nav">
                        {!user.isAuthenticated ? <a onClick={openLoginDialog}>{t('Sign_In')}</a>
                            : <a onClick={logout}>{t('Logout_')}</a>
                        }
                        {/*<a onClick={openRegisterDialog} className="">{t('Register')}</a>*/}
                    </div>
                    {isAuthenticated && <>
                        <div className="primary-nav has-mega-menu">
                            <ul className="navigation">
                                <NavbarDropdown title={t('Projects')}
                                                onClick={showProjects}>
                                    <DropdownItem
                                        title={<><i className="fa fa-sitemap"/> <span>{t('Add_Project')}</span></>}
                                        onClick={openAddProjectDialog}
                                    />
                                    <DropdownItem
                                        title={<>
                                            <i className="fa fa-pencil-square-o"/>
                                            <span>{t('Edit_Project')}</span>
                                        </>}
                                        onClick={openEditProjectDialog}
                                    />
                                    <DropdownItem
                                        title={t('List_')}
                                        icon={<i className="fa fa-list-ul"/>}
                                    >
                                        {projects.map(project => <li key={project._id}>
                                            <Link to={'/projects/' + project._id} className={`add-listing`}>
                                                <i className="fa fa-map-marker"/>
                                                <span>{project.name.split(' ').map(word => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}</span>
                                            </Link>
                                        </li>)}
                                    </DropdownItem>
                                </NavbarDropdown>
                                <NavbarDropdown title={t('Controls')}>
                                    <DropdownItem
                                        title={t('Map_Type')}
                                        icon={<i className="fa fa-globe"/>}
                                    >
                                        <DropdownItem title={t('Best_Server')}
                                                      onClick={setBestSever}/>
                                        <DropdownItem title={t('C_2_I')}
                                                      onClick={setC2I}/>
                                    </DropdownItem>
                                    <DropdownItem
                                        title={t('Setting_')}
                                        icon={<i className={'fa fa-cog'}/>}
                                    >
                                        <DropdownItem
                                            title={t('Rearrange_Bins')}
                                            onClick={clusterProject}
                                        />
                                        <DropdownItem
                                            title={t('import_project_file_')}
                                            onClick={()=>configFileManagerRef.current.uploadFile()} />
                                        <DropdownItem
                                            title={t('export_project_file_')}
                                            onClick={()=>configFileManagerRef.current.downloadFile()} />
                                        <ConfigFileManager ref={configFileManagerRef} />
                                    </DropdownItem>
                                    {/*<DropdownItem*/}
                                    {/*title={<><i className="fa fa-sliders"/><span>{t('Parameters')}</span></>}*/}
                                    {/*onClick={openParametersDialog}*/}
                                    {/*/>*/}
                                    <DropdownItem
                                        title={t('Reset_Display')}
                                        icon={<i className="fa fa-window-close-o"/>}
                                        onClick={resetBins}
                                    />
                                    <DropdownItem
                                        title={t('Tech_Conversion')}
                                        icon={<i className="fa fa-magic"/>}
                                        onClick={()=> openDialog(modals.TECH_CONVERSION)}
                                    />
                                </NavbarDropdown>
                            </ul>
                        </div>
                        {mapType === Constants.mapTypes.BEST_SERVER && <>
                            <div className="third-nav">
                                <A className={"btn btn-primary btn-small btn-rounded icon shadow add-listing "}
                                   onClick={onOptimizeProject}>
                                    <i className={"fa fa-gear " + (!fetching ? '' : "fa-spin")}/>
                                    <span>{t('Show_Optimal_Coverage')}</span>
                                </A>
                                <A className="btn btn-outline-secondary btn-small btn-rounded icon add-listing"
                                   onClick={onDisplayCoverage}>
                                    <i className="fa fa-object-group"/>
                                    <span>{t('Display_Coverage')}</span>
                                </A>
                            </div>

                        </>}
                        {mapType === Constants.mapTypes.C2I && <div className="third-nav">
                            <A className="btn btn-primary btn-small btn-rounded icon shadow add-listing"
                               onClick={onChoseSites}>
                                <i className="fa fa-window-restore"/>
                                <span>{t('Choose_Different_Sites')}</span>
                            </A>
                        </div>}
                        {showCoverage && <Div>{t('Coverage') + ' '}
                            <StyledProgress percent={Number(coverage !== 'NaN' && coverage ? coverage : 0)}/>
                            {(mapLayerTypeType === 'Bit_Rate' ? t('Bit_Rate_Threshold') : t('For_Rssi_Edge')) + ' '}
                            <StyledBadge variant='secondary'>{rssiThreshold}
                                <small> {isBitRate ? 'Mbps' : 'dbm'}</small>
                            </StyledBadge>
                        </Div>}
                        <Div>
                            {t('Total_Cost') + ' '}<StyledBadgeCost
                            onClick={handleCostClick}
                            variant='dark'>{(totalCost > 0 ? '$ ' + Number(totalCost).toLocaleString() : totalCost)}</StyledBadgeCost>
                        </Div>
                        {/*{projectParams.devParams.minCoverage && <Div>*/}
                        {/*{t('Minimal_Coverage') + ' '}*/}
                        {/*<StyledBadge*/}
                        {/*variant={'secondary'}>*/}
                        {/*{isProd ? '--' : (projectParams.devParams.minCoverage)}*/}
                        {/*<small> %</small>*/}
                        {/*</StyledBadge>*/}
                        {/*</Div>}*/}
                        <div className="nav-btn">
                            <i/>
                            <i/>
                            <i/>
                        </div>
                        {/*<ProgressBar animated now={45} />*/}
                    </>}
                </div>
                <div className="right">
                    <a href="/" className="brand">
                        <img className="logo-icon" src={logo1} alt="flycomm icon"/>
                        <img className="logo-text" src={logo2} alt="flycomm text"/>
                    </a>
                </div>
            </nav>
        </NavbarHeader>
    )
}

function mapStateToProps(reduxState) {
    return {
        lines: reduxState.map.lines,
        coverage: reduxState.map.coverage,
        mapType: reduxState.map.mapType,
        user: reduxState.user,
        projects: reduxState.map.projects,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        showCostsTable: isCostsTable => dispatch(showCostsTable(isCostsTable)),
        setMapType: mapType => dispatch(setMapType(mapType)),
        logoutUser: () => dispatch(logoutUser()),
        resetProject: () => dispatch(resetProject()),
        resetProjects: () => dispatch(resetProjects()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);