import React from "react";
import {useGoogleMap} from "@react-google-maps/api";
import Bin from "./Bin";
import useMapContentEffects from "../../Hooks/useMapContentEffects";
import PropTypes from 'prop-types';
import AreaSelectionPolygon from "../MapControls/AreaSelectionPolygon";
import OverlayScreenView from "../MapControls/OverlayScreenView";
import Constants from "../../controller/Constants";
import useProject from "../../Hooks/useProject";
import {useMapState} from "../../Hooks/map";
import LocationMarker from "./LocationMarker";
import SitesClusterer from "./SitesClusterer";
import { usePolygonPath } from "../../Hooks/common";


function MapContent({chosenSites, binsMapper}) {
    const map = useGoogleMap();
    const project = useProject();
    const mapState = useMapState();
    const isAreaSelectionState = mapState === Constants.mapStates.AREA_SELECTION;
    const polygonPath = usePolygonPath();
    const sites = chosenSites ? project.sites.filter(site => chosenSites.includes(site._id)) : project.sites;
    const {center: mapCenter, bounds: mapBounds, binsArray} = useMapContentEffects({
        map, binsMapper, sites, polygonPath, chosenSites
    });
    return <>
        <SitesClusterer mapBounds={mapBounds} sites={sites} chosenSites={chosenSites}/>
        {/*bins display*/}
        {binsArray.map((bin) => (// height array contain objects with nested list 'bins'
            <Bin
                details={{signal: bin.signal, location: bin.location}}
                signal={bin.signal}
                location={bin.location}
                key={`${bin.location.lat},${bin.location.lng}`}
                fromSites={bin.sites}
            />
        ))}
        {(isAreaSelectionState || polygonPath.length > 2) && <AreaSelectionPolygon
            center={mapCenter}
            bounds={mapBounds}
            map={map}
            zoom={undefined}/>}
        <OverlayScreenView bins={binsArray}/>
        {/*location stats Marker*/}
        <LocationMarker/>
    </>;
}

MapContent = React.memo(MapContent);
MapContent.propTypes = {
    binsMapper: PropTypes.func.isRequired,
    chosenSites: PropTypes.arrayOf(PropTypes.string),
};
export default MapContent;