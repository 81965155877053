import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

const Delete = styled.i`
     font-family: 'Lato', sans-serif;
     align-content: flex-end;
     width: auto;
     min-width: 0;
     &.fa.fa-trash{
        border: 2px rgba(238,16,0,0.64) solid;
        background-color:  rgba(221,0,2,0.1);
        color:  rgb(110,0,2);
     }
     &.fa.fa-trash:before{
        font-family: FontAwesome,serif;;
        float: right;
        margin-left: ${({Label})=>Label?'7px':'0'};
     }
`;

function DeleteButton({label, onClick, rounded = true, ...props}) {
    return (
        <Delete
            {...props}
            Label={label}
            onClick={onClick}
            className={`btn ${rounded ? 'btn-rounded' : ''} fa fa-trash ${props.className || ''}`}>{label}</Delete>
    )
}

DeleteButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};


export default DeleteButton;