import {antennaTypesV2, antennaConfigs, siteModes, technologies, antennaModels} from "./enums";
import {BINS_PLACEMENT, PROJECT, SITE} from "./index";
import mongoose from 'mongoose';

const {ObjectId} = mongoose.Schema.Types; 
export const sectorSchema = new mongoose.Schema({
    created: {type: Date, default: Date.now, immutable: true},
    lastModified: {type: Date, default: Date.now},
    project: {type: ObjectId, ref: PROJECT, required: true, immutable: true},
    site: {type: ObjectId, ref: SITE, required: true/*, immutable:true*/},
    siteMode: {type: String, required: true, enum: siteModes, immutable: true},
    cost: {type: Number, default: 0,},
    height: {type: Number, required: true},
    azimuth: {type: Number, default: null, set: v => v % 360, alias: 'direction'},
    number: {type: Number, default: null},
    technology: {type: String, default: null, enum: technologies},
    antennaModel: {type: String, default: null, enum: [...antennaTypesV2, ...antennaModels]},
    electricTilt: {type: Number, default: null, min: -20, max: +20},
    mechanicTilt: {type: Number, default: null, min: -20, max: +20},
    txLoss: {type: Number, default: 0},
    txPower: {type: Number, default: 0},
    output: {type: Number, default: null},
    frequency: {type: Number, default: null},
    antennaConfig: {type: String, default: antennaConfigs[0], enum: antennaConfigs},
    EiRP: {type: Number, default: null, min: -130, max: -40},
    defaultValues: {
        cost: {type: Number, default: 0,},
        height: {type: Number, required: true},
        azimuth: {type: Number, default: null, min: 0, max: 360, alias: 'direction'},
        number: {type: Number, default: null},
        technology: {type: String, default: null, enum: technologies},
        antennaModel: {type: String, default: null, enum: [...antennaTypesV2, ...antennaModels]},
        electricTilt: {type: Number, default: null, min: -20, max: +20},
        mechanicTilt: {type: Number, default: null, min: -20, max: +20},
        txLoss: {type: Number, default: 0},
        txPower: {type: Number, default: 0},
        output: {type: Number, default: null},
        frequency: {type: Number, default: null},
        antennaConfig: {type: String, default: null, enum: antennaConfigs},
        EiRP: {type: Number, default: null, min: -130, max: -40},
    },
    antennaModels: {
        type: [{type: String, enum: antennaModels}],
        default: antennaModels[0],
    },
    antennaPrices: {
        TYPE_1X1: {type: Number, default: 0},
        TYPE_2X2: {type: Number, default: 0},
        TYPE_4X4: {type: Number, default: 0},
        TYPE_8X8: {type: Number, default: 0},
        TYPE_8X8_STEERED: {type: Number, default: 0},
        TYPE_16X16: {type: Number, default: 0},
        TYPE_32X32: {type: Number, default: 0},
        TYPE_64X64_STEERED: {type: Number, default: 0},
    },
    driveTestFile: {
        bucket: {type: String, default: null},
        fileId: {type: String, default: null},
    },
    binsPlacements: [
        {type: ObjectId, ref: BINS_PLACEMENT},
    ],
}, {timestamps: true});

export const sectorCompareValues = ['cost', 'height', 'number', 'technology', 'antennaModel',
    'electricTilt', 'mechanicTilt', 'txLoss', 'txPower', 'azimuth', 'output', 'frequency', 'antennaConfig',
    'EiRP'].map(field => ({field, ...sectorSchema[field]/*,defaultValue:sectorSchema[field]*/}));