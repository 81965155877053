import React from "react";
import {OverlayView} from "@react-google-maps/api";
import TableBS from "react-bootstrap/Table";
import SmallModal from "../StyledComponents/SmallModal";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import Constants from "../../controller/Constants";
import {mapLayers} from "../../controller/Constants";
import {getBitRateFromBin, getLayerType, reduceBinSitesToHighestRssi} from "../../controller/mapper";
import {useProjectParams} from "../../Hooks/useProject";

const StyledTable = styled(TableBS)`
  &.table {
    margin: 0;
  }
`;

function BinDetails({position, mapType, details, signal, location, show, fromSites}) {
  const {t} = useTranslation();
  const projectParams = useProjectParams();

  function getBinsDetails() {
    const {mapLayer} = projectParams;
    const isBitRateCapacity = projectParams.mapLayer === mapLayers.BIT_RATE_CAPACITY;
    const bestServerObj = fromSites.reduce(reduceBinSitesToHighestRssi, {rssi: -Infinity});
    const bestServer = bestServerObj.site;
    let detailsRes = [{key: "best_server", value: bestServer}];
    if (isBitRateCapacity) {
      detailsRes.push({key: "bit_rate_b", value: getBitRateFromBin(details, fromSites, projectParams)});
    }
    let key = "rssi";
    const signalType = getLayerType(mapLayer);
    if (!["MCS", "Bit_Rate"].includes(signalType)) {
      key = signalType.toLowerCase();
    }
    if (mapType === Constants.mapTypes.BEST_SERVER) return [...detailsRes, {key: key, value: Number(signal.toFixed(3))}];
    if (mapType === Constants.mapTypes.C2I) return [...detailsRes, {key: key + "_gap", value: Number(signal.toFixed(3))}];
  }

  const binDetails = getBinsDetails();
  return (
    <OverlayView position={position} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
      <SmallModal>
        <StyledTable responsive size="sm">
          {details && (
            <tbody>
              {binDetails.map(({key, value}) => (
                <tr key={key}>
                  <td>{t(key)}</td>
                  <td>{value}</td>
                </tr>
              ))}
            </tbody>
          )}
        </StyledTable>
      </SmallModal>
    </OverlayView>
  );
}

export default BinDetails;
