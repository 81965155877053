import React, {useEffect, useState} from 'react';
import {GroundOverlay} from '@react-google-maps/api';
import {useSelector} from 'react-redux';
import {usePredictionState} from "../../Hooks/common";

function OverlayScreenView({bins,}) {
    const {highlightOpacity} = useSelector(mapStateToProps);
    const [bounds, setBounds] = useState(null);
    const [display, setDisplay] = useState(false);
    const [predictionState] = usePredictionState();
    useEffect(() => {
        if (highlightOpacity > 0 && bins.length > 0 && bounds && bounds.length > 1) {
            if (!display) setDisplay(true)
        } else {
            if (display) setDisplay(false)
        }
    }, [bounds, bins]);
    useEffect(() => {
        try {
            setDisplay(false);
            if (highlightOpacity > 0 && bins.length > 0) {
                const bounds_gm = new window.google.maps.LatLngBounds();
                bins.forEach(point => {
                    bounds_gm.extend(point.location)
                });
                const boundsJs = bounds_gm.toJSON();
                boundsJs.west -= 0.01;
                boundsJs.east += 0.01;
                boundsJs.south -= 0.01;
                boundsJs.north += 0.01;
                bounds_gm.union(boundsJs);
                setBounds(bounds_gm);
            } else {
                setBounds(null)
            }
        } catch (e) {
            console.error(e);
        }
    }, [bins, highlightOpacity]);
    // this component does not support on props change...
    // so you have to unmount and mount to apply prop changes.
    return Boolean(display && !predictionState) && <GroundOverlay
        url={'https://dummyimage.com/60x60/ffffff/0011ff.jpg&text=+'}
        bounds={bounds}
        opacity={highlightOpacity}/>
}

function mapStateToProps(reduxState) {
    return {highlightOpacity: reduxState.map.highlightOpacity}
}

export default OverlayScreenView;