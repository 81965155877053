import React from 'react';
import styled from 'styled-components';

const I = styled.i`
    &.fa.fa-arrow-left{
        position: absolute;
        top: 0;
        right: 0;
        margin: 5px 20px 0 0;
        font-size: 20px;
        color:var(--grey-dark-blue)
    }
`;

function BackButton({children,className,...props}) {
    return <I className={'fa fa-arrow-left ' + [className]}
              {...props}>{children}</I>
}

export default BackButton;