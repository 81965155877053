function generateCallbackId(callbacks) {
    const ids = callbacks.map(callback =>{
        return Number(callback.id)
    });
    return Math.max(0,...ids) + 1;
}

const EventEmitter = {
    events: {},
    dispatch: function (event, data) {
        if (!this.events[event]) {
            // console.log('(EventEmitter.dispatch) event '+event + ' did not subscribed');
            return false;}
        this.events[event].forEach((callbackObj) => {
            callbackObj.callback(data);
        });
        return true;
    },
    subscribe: function (event, callback) {
        // console.log('(EventEmitter.subscribe) eventName: '+event + ' subscribed');
        if (!this.events[event]) this.events[event] = [];
        const callbackId = generateCallbackId(this.events[event]);
        this.events[event].push({callback,id:callbackId});
        return callbackId;
    },
    unsubscribe: function (event,callbackId){
        if (!this.events[event]) return false;
        // console.log('(EventEmitter.unsubscribe)( eventName: '+event + ', id:'+callbackId+')- unsubscribed');
        this.events[event]= this.events[event].filter(callbackObj=>callbackObj.id !== callbackId);
        return true;
    }
};
export default EventEmitter;