import React from 'react';
import {useTranslation} from "react-i18next";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import styled from "styled-components";
import Button from "../Common/Buttons/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import InputWithUnits from "../Common/Inputs/InputWithUnits";

const StyledButton = styled(Button)`
      margin-left: 4px;
`;
const ListGroupItem = styled(ListGroup.Item)`
.form-group-sf{
        display: flex;
        justify-content: start;
        flex-direction: row;
        margin:0;
        .form-label{
            flex-shrink:0;
            flex-basis: 110px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin: 0;
        }
        .form-control{
            text-align: end;
            padding: 0 3px;
            margin:0;
        }
    }
`;

// const DEFAULT_ANTENNA_PRICES = {
//     TYPE_1X1: 0,
//     TYPE_2X2: 0,
//     TYPE_4X4: 0,
//     TYPE_8X8: 0,
//     TYPE_8X8_STEERED: 0,
//     TYPE_16X16: 0,
//     TYPE_32X32: 0,
//     TYPE_64X64_STEERED: 0,
// };

function PricesPopover({selectedAntennaModels, onChange, antennaPrices, ...props}) {
    const {t} = useTranslation();
    // useEffect(() => {
    //     console.log('useEffect:', antennaPrices);
    // }, [antennaPrices]);
    const changeInput = ((val, typeName) => {
        // console.log('PricesPopover:', {val, typeName, ...antennaPrices});
        onChange({...antennaPrices, [typeName]: val});
    });
    return (
        <OverlayTrigger
            rootClose
            trigger={'click'}
            key={'left'}
            placement={'left'}
            overlay={
                <Popover
                    id={`popover-positioned-${'left'}`}>
                    <Popover.Title as="h3">{t('Choose_Antenna_Prices')}</Popover.Title>
                    <Popover.Content>
                        <ListGroup variant="flush">
                            {selectedAntennaModels.map((typeName, i) => {
                                const price = antennaPrices[typeName];
                                return (<ListGroupItem key={typeName}>
                                    <Form.Group className={'form-group-sf'} controlId={`exampleForm.${typeName}`}>
                                        <Form.Label column={false} title={typeName}>{t(typeName)}</Form.Label>
                                        <InputWithUnits
                                            type={'number'}
                                            value={price}
                                            units={' $'}
                                            key={typeName + '-input'}
                                            keyProp={typeName}
                                            onChange={changeInput}
                                        />
                                    </Form.Group>
                                </ListGroupItem>)
                            })}
                        </ListGroup>
                    </Popover.Content>
                </Popover>
            }
        >
            <StyledButton
                variant='outline'
                color={'primary'}>
                {t('Antenna_Prices')}
            </StyledButton>
        </OverlayTrigger>
    )
}

export default PricesPopover;