const technologies = [
    'UMTS-2100',
    // 'LTE-700',
    // 'LTE-1800',
    'LTE-NB',
    '5G-NR-2600',
    '5G-NR-3500',
    'WiFi-5',
    'WiFi-6',
    'GSMR',
    'LTE_NB-IoT',
    'LTE_1800',
    'LTE_700',
    'LTE_2600',
    null,
];

const siteModes = [
    'Predesign',
    'Postdesign',
];
const antennaConfigs = [
    'Model-A',
    'Model-B',
    'Steered',
    null,
];

const channelsBWs = [5, 10, 20, 40, 60, 80, 100,`60`, null];

const antennaModels = [
    "TYPE_1X1",
    "TYPE_2X2",
    "TYPE_4X4",
    "TYPE_8X8",
    "TYPE_8X8_STEERED",
    "TYPE_16X16",
    "TYPE_32X32",
    "TYPE_64X64_STEERED",
    null,
];
const antennaTypesV2 = ['TNA352A33', 'HBXX-6516DS-VTM', 'ADFD1820-6565B-XDM', 742234,
    'APXVRR13-C', 'OR8171217-6-C-5-15-L', 'OR8171217-6-C-5-15-R',
    'TBXLHA-6565C-VTM', 742236, 'DBXLH-6565A-VTM', 'DBXLH-6565C-VTM',
    'APXV9R26B-C', 'CV3PX310R1', 'CV3PX308R1', 'TBXLHB-6565A-VTM',
    742215, 'ADFDP182-6565B-XDM', 'DTA_D_18/34', 'RRZZHHTT-65B-R6H4',
    80010992, 742241, 'ODI2-065R17M18JJ02-GQ V1', '5980300P',
    'RVV-33B-R3', 'RV4PX310R', 741794, 742218, 'APXV9R13B-C', 738454,
    'ODI-065R15MM18JJJ02-GQ', 'ODI-065R12M15JJJ02-GQ',
    'AES-P4-2A', 'RRZZHHTT-65D-R6', 'CW10X65F15', 742211,
    'OR8171217-6-C-5-20-L', 741572, 'HBXX-3817TB1-VTM', '2NPX210R-V1',
    'RRVVHHTT-65D-R6', '80010622v01', 742264, 742237, 742149,
    'ODI-065R12E15KJJ-G', 'TNA352A33R', 80010864, 'YAGI-19E-17101880',
    'AMS1710-2170-14-14', 'OR8241710', 742266, 'פנימית', 742270,
    'RRZZHHTT-65A-R6H4', '80010692V01', 'OR83596017102170-6-5-15',
    738187, 'CW10X65F20', 'HBX-4517DS-VTM', 739489, '5NPX1006F',
    'ODI2-065R16M18SKD02-Q', 'CW11X65F1510', 'TNA362A33R',
    'OR8171217-6-C-5-20-R', '10P-2L8M-D5', 'CMA-BTLBHH 6517_21_21',
    7760, '932QLG65R2EB', 742351, 'MA801808DB-8',
    'Flexi Zone Pico Internal RF Antenna', 'D74223434',
    'OR69896017102170-6-5-17', 'OR8171217-6-C-12-R', 742235, 8010992,
    741989, 'OR80017102170-CP', 'HBX-6513DS-VTM', 'CMA-B/3324/E0-6',
    'CP12', 800442008, 742212, 'ANT1800Y10-WR', '8720.0ST.B400.00',
    742226, 'BGD3-800TV', 'HWXX-6516DS-VTM', 'M5277', 'OR8171217-6-C-12-L',
    'D74221534', 'TNA342A33R', 'TNA342A33R'].map(String);
antennaTypesV2.push(null);

const mapLayerValues = [
    'RSSI - Coverage Optimized',
    'RSSI - Capacity Optimized',
    'MCS - Coverage Optimized',
    'MCS - Capacity Optimized',
    'Bit Rate - Coverage Optimized',
    'Bit Rate - Capacity Optimized',
    'RSRP - Coverage Optimized',
    'RSRP - Capacity Optimized',
    'RSRQ - Coverage Optimized',
    'RSRQ - Capacity Optimized',
];
module.exports = {
    technologies,
    siteModes,
    channelsBWs,
    antennaConfigs,
    antennaModels,
    antennaTypesV2,
    mapLayerValues,
};