import styled from "styled-components";
import Form from "react-bootstrap/Form";
import Slider from "react-input-slider";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";

const SliderContainer = styled.div`
    display:flex;
    align-items: flex-end;
    .add-units{
      position: relative;
    }
    .slider-input{
      width:100px;
      margin-right:5px;
    }
    .add-units::after {
      content: '${props=>props.units}';
      position: absolute;
      top: 12px;
      right: .5em;
      transition: all .05s ease-in-out;
    }
    .slider{
      margin: 0 5px 10px 5px;
      flex-grow: 1;
    }
    
`;


function SliderInput({units, value, onChange: handleChange, placeholder, min, max, step, disabled}) {
    const [input, setInput] = useState(value);
    useEffect(() => {
        setInput(value);
    }, [value]);

    function inputChange(ev) {
        let inputString = ev.target.value;
        setInput(inputString);
    }

    // console.log('render input', input);
    const sliderChange = ({x}) => {
        const xString = String(x);
        // console.log("sliderChange->x: ", xString);
        // console.log("sliderChange->input: ", input);
        handleChange(Number(xString));
    };

    function inputBlur() {
        handleChange(Number(input));
    }

    function sliderDragEnd(event) {
        // console.log("sliderDragEnd event", event);
        // setTimeout(() => {
        //     console.log("sliderDragEnd input: ", input);
        //     handleChange((input));
        // }, 0)
    }

    return <SliderContainer units={units}>
        <div className={'add-units'}>
            <Form.Control type="text"
                          className='slider-input'
                          disabled={disabled}
                          placeholder={placeholder}
                          onChange={inputChange}
                          onBlur={inputBlur}
                          value={input}/>
        </div>
        <Slider
            styles={{
                active: {
                    backgroundColor: '#595763'
                },
            }}
            className={'slider'}
            axis="x"
            xstep={step}
            xmin={min}
            xmax={max}
            x={Number(input)}
            onChange={sliderChange}
            onDragEnd={sliderDragEnd}
            disabled={disabled}
        />
    </SliderContainer>
}

SliderInput.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    onChange: PropTypes.func.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    disabled: PropTypes.bool
};
export default SliderInput;