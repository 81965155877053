import {useFetchLoader} from "./common";
import {useCallback} from "react";

export function useFetchCallback(cb, deps, {setError}) {
    const [, setLoading] = useFetchLoader();

    async function callback(...args) {
        try {
            setLoading(true);
            await cb(...args);
        } catch (e) {
            console.error(e);
            if (typeof setError === 'function') {
                setError(e);
            }
        } finally {
            setLoading(false);
        }
    }

    return useCallback(callback, deps);
}