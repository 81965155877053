import React from 'react';
import useAddress from "../../Hooks/useAddress";
import PropTypes from "prop-types";
import UnstyledCard from "react-bootstrap/Card";
import styled from "styled-components";
import{GoogleMap,Marker} from "@react-google-maps/api";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const Card = styled(UnstyledCard)`
    &.card{
          margin-bottom:30px;
          background-color: #595763;    
          color:#fff;
          cursor: pointer;
          //transition:  0.2s ease-out;
    }
    &.card:hover{
      box-shadow: 0 0 2px 2px var(--light-camel);
      //transition: all 0.2s ease-in;
      //box-shadow: 1px 3px 2px rgba(0,0,0,0.3);
      //transform: translate(-1px,-3px);
    }
    &.card.active{
      box-shadow: 0 0 2px 3px var(--light-camel-highlight);
    }
`;
const IconContainer = styled.span`
    float:right;
`;
function SiteCard({site,onClick:chooseSite,check}) {
    const address = useAddress(site.location);
    return <Card className={check? 'active':''} onClick={() => {
        chooseSite(site)
    }}>
    {/*<Card.Header></Card.Header>*/}
        <Card.Body>
            <Row>
                <Col sm={4}>
                    {site.name}
                    <Card.Text>{address}</Card.Text>
                    {check&&<IconContainer>{check}  <i className={'fa fa-check'}/></IconContainer>}
                </Col>
                <Col sm={8}>
                    <GoogleMap
                        mapContainerStyle={{
                            height: "100px",
                            width: "100%"
                        }}
                        zoom={14}
                        center={{lat:Number(site.location.lat),lng:Number(site.location.lng)}}
                    >
                        <Marker
                            position={{lat:Number(site.location.lat),lng:Number(site.location.lng)}}
                        />

                    </GoogleMap>
                </Col>
            </Row>



        </Card.Body>
    </Card>
}

SiteCard.propTypes = {
    site: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    check: PropTypes.string,
}
export default SiteCard;