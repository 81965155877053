import React, {useRef, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {setMapState} from "../../store/actionCreators/map";
import {connect} from 'react-redux';
import ButtonsSelect from "../Common/Buttons/ButtonsSelect";
import {usePredictionState} from "../../Hooks/common";

const Div = styled.div`
  position: absolute;
  top: 10px;
  left:10px;
  text-align: center;
`;

function PredictionSwitch() {
    const {t} = useTranslation();
    const [predictionState, setPredictionState] = usePredictionState();
    const timeoutRef = useRef();
    const choices = [
        {value: 'prediction', label: t('predict_l'), checked: predictionState},
        {value: 'samples', label: t('samples_l'), checked: !predictionState},
    ];
    return <Div>
        <ButtonsSelect
            values={choices}
            onSelect={(obj) => {
                const {value: val} = obj;
                if (timeoutRef.current) clearTimeout(timeoutRef.current);
                timeoutRef.current = setTimeout(() => {
                        setPredictionState(val === 'prediction');
                        clearTimeout(timeoutRef.current);
                        timeoutRef.current = null;
                    }
                    , 0);

            }}/>
    </Div>
}

function mapStateToProps(reduxState) {
    return {}
}

export default connect(mapStateToProps, {setMapState})(PredictionSwitch);