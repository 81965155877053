import React, {useCallback, useEffect, useState} from 'react';
import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import {useTranslation} from "react-i18next";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";
import CButton from '../Common/Buttons/Button';
import {useProjectParams, useProjectState} from "../../Hooks/useProject";
import {Container} from "../StyledComponents/LayersFormContainer";
import Api from '../../controller/ApiManager';
import {useAddToast, useFetchLoader} from "../../Hooks/common";
import {useMapLayerInputs} from "../../Hooks/useMapLayerInputs";
import InputWithUnits from "../Common/Inputs/InputWithUnits";
import {isParamsClusteringFieldsChanged} from "../../controller/dataOperations";
import SectorsDialog from '../Sector/SectorsDialog';
import {useSelector} from 'react-redux';



function sortInputs(a, b) {
    const sortingArr = ['mapLayer', 'technology', 'frequencyBand', 'channelBW', 'rssiThreshold', 'downUpLinkRatio',
        'antennaPolarization', 'minCoverage', 'polarizationLoss', 'systemGain', 'binReliability', 'binResolution', 'samplesReliability'];
    if (!sortingArr.includes(a.key)) return 0;
    return sortingArr.indexOf(a.key) - sortingArr.indexOf(b.key);
}

function getParamsFromInputs(inputs, projectParams) {
    const {devParams, ...params} = projectParams;
    const getValueForField = (fieldName) => inputs.find(item => item.key === fieldName).value;
    // const isBitRate = ['Bit Rate - Coverage Optimized', 'Bit Rate - Capacity Optimized'].includes(projectParams.mapLayer);
    return {
        ...params,
        mapLayer: projectParams.mapLayer,
        technology: getValueForField('technology'),
        frequencyBand: Number(getValueForField('frequencyBand')), // Hack to send number and not a string
        channelBW: getValueForField('channelBW'),
        rssiThreshold: getValueForField('rssiThreshold'),
        downUpLinkRatio: getValueForField('downUpLinkRatio'),
        antennaPolarization: getValueForField('antennaPolarization'),
        devParams: {
            ...devParams,
            minCoverage: getValueForField('devParams.minCoverage'),
            // polarizationLoss: getValueForField('devParams.polarizationLoss'),
            systemGain: getValueForField('devParams.systemGain'),
            binReliability: getValueForField('devParams.binReliability'),
            binResolution: getValueForField('devParams.binResolution'),
            samplesReliability: getValueForField('devParams.samplesReliability'),
        },
    }
}

function MapLayerControls({}) {
    const [open, setOpen] = useState(false);
    const userGroup = useSelector(state => state.user.user.groups);
    const [arrowUp, setArrowUp] = useState(open);
    const [project, setProject] = useProjectState();
    const addToast = useAddToast();
    const projectParams = useProjectParams();
    const [,setFetching] = useFetchLoader();
    const {t} = useTranslation();
    const {defaultInputs, inputs, setInputs} = useMapLayerInputs();

    useEffect(() => {
        const timeId = setTimeout(() => {
            setArrowUp(open);
        }, 400);
        return () => {
            clearTimeout(timeId);
        }
    }, [open]);

    const changeInput = useCallback((ev, field) => {
        const currentInput = inputs.find(data => data.key === field);
        const inputClone = JSON.parse(JSON.stringify(currentInput));
        inputClone.value = ev.target ? ev.target.value : ev;
        const newInputs = inputs.map(inputItem => inputItem.key === field ? inputClone : inputItem);
        setInputs(newInputs);
    }, [inputs.map(({value}) => value).join(',')]);

    async function updateParams() {
        const defaultValues = defaultInputs.sort(sortInputs).map(item => ({value: item.value, key: item.key}));
        const stateValues = inputs.sort(sortInputs).map(item => ({value: item.value, key: item.key}));
        let changedInputs = defaultValues.filter((item, i) => item.value !== stateValues[i].value);
        let updatedProject = JSON.parse(JSON.stringify(project));
        if (changedInputs.map(item => item.key).includes('mapLayer')) {
            try {
                changedInputs = changedInputs.filter(item => item.key !== 'mapLayer');
                setFetching(true);
                const newMapLayer = inputs.find(item => item.key === 'mapLayer').value;
                if (typeof newMapLayer !== 'string' || !newMapLayer.length > 2) {
                    setFetching(false);
                    return console.error(Error('no map layer value'));
                }
                const newProject = await Api.Project.editProject({...project, currentMapLayer: newMapLayer});
                updatedProject = {...project, currentMapLayer: newProject.currentMapLayer};
                if (changedInputs.length === 0) {
                    setProject(updatedProject);
                }
            } catch (e) {
                addToast(e);
                console.error(e)
            } finally {
                if (changedInputs.length === 0)
                    setFetching(false);
            }
        }

        const isInputChanged = changedInputs.length > 0;
        if (isInputChanged) {
            try {
                setFetching(true);
                const newParams = await
                    Api.ProjectParams.editProjectParams(project._id, getParamsFromInputs(inputs, projectParams));
                const newProject = {
                    ...updatedProject,
                    mapLayersParams: project.mapLayersParams.map(layerParams => {
                        if (layerParams.projectParams._id === newParams._id) {
                            return {...layerParams, projectParams: newParams};
                        }
                        return layerParams;
                    })
                };
                const shouldFetchProject = isParamsClusteringFieldsChanged(projectParams, newParams);
                setProject(shouldFetchProject ? await Api.Project.getProject(project._id, {binsPopulated: true}) : newProject);
            } catch (e) {
                addToast(e);
                console.error(e)
            } finally {
                setFetching(false);
            }
        }
    }

    return (
        <Container>
            <Card className={'map-layer-card'}>
                <Card.Header className={'card-header-sf'} onClick={() => setOpen(!open)}>
                    <div
                        className={'title-layer'}>{t(projectParams.mapLayer.replace(' -', '').split(' ').join('_') + '_tl')}</div>
                    <i className={`fa fa-angle-${!arrowUp ? 'down' : 'up'}`} aria-hidden="true"/>
                </Card.Header>
                <Collapse in={open}>
                    <div>
                        <ListGroup variant="flush">
                            {inputs.map(({label, key, disabled, inputType, value, selectList, units, disabledOptions}) => (
                                <ListGroup.Item key={key}>
                                    <Form.Group className={'form-group-sf'} controlId={"exampleForm."+key}>
                                        <Form.Label title={label}>{label}</Form.Label>
                                        {inputType === 'text' || inputType === 'number' ?
                                            <InputWithUnits
                                                {...{type:inputType, value, disabled, units}}
                                                key={key + '-input'}
                                                keyProp={key}
                                                onChange={changeInput}
                                            />
                                            :
                                            <Form.Control
                                                key={key + '-input'}
                                                as={'select'}
                                                type={inputType}
                                                value={value}
                                                name={key}
                                                disabled={disabled}
                                                onChange={ev => changeInput(ev, key)}
                                                className={'select-sf'}
                                                title={inputType === 'select' ? String(value).split(' ').join('_') + (units ? ' ' + units : '') : null}>
                                                {selectList
                                                    .map(val => ({
                                                        label: (typeof val === 'string' ? t(val.split(' ').join('_')) : val) + (units ? ' ' + units : ''),
                                                        value: val,
                                                        disabled: disabledOptions ?
                                                            disabledOptions.includes(val)
                                                            : false
                                                    }))
                                                    .map(item => (
                                                        <option
                                                            key={item.value}
                                                            disabled={item.disabled}
                                                            value={item.value}>
                                                            {item.label}
                                                        </option>)
                                                    )
                                                }
                                            </Form.Control>
                                        }
                                    </Form.Group>
                                </ListGroup.Item>))}
                        </ListGroup>
                        <div className={'button-group-sf'}>
                            <CButton
                                onClick={() => setInputs(defaultInputs)}
                                variant="outline"
                                color={"dark"}
                            >
                                {t('Reset_sf')}
                            </CButton>
                            <Button
                                onClick={updateParams} 
                            >
                                {t('Apply_sf')}
                            </Button>
                        </div>
                    </div>
                </Collapse>
            </Card>
            <Button
                className={'open-button ' + (arrowUp ? 'flip-horizontal-bottom' : '')}
                variant="outline-dark"
                onClick={() => setOpen(!open)}
            >
                <i className={`fa fa-arrow-down ${arrowUp ? 'up' : 'down'}`} aria-hidden="true"/>
            </Button>
        </Container>
    )
}

export default MapLayerControls;