import React, {useRef, forwardRef, useImperativeHandle } from 'react';
import styled from "styled-components";

const StyledConfigFileManager = styled.div`
    display: none;
`;

function FileManager(props,ref) {
    const downloadButtonRef = useRef(null);
    const inputRef = useRef(null);
    const {fileName, data, onFileChange, accept} = props;

    useImperativeHandle(ref, () => ({
        uploadFile,
        downloadFile
      }));

    function uploadFile(){
        inputRef.current?.click();
    }
    function downloadFile(){
        downloadButtonRef.current?.click();
    }



    function handlOnChange(){
        inputRef.current?.files &&
        handleUploadFile(inputRef.current.files[0]);
      };


    function handleUploadFile(file) {
        const fileReader = new FileReader();
        
        fileReader.onload = (ev) => {
            onFileChange(ev.target.result);
        };

        if (file)
            fileReader.readAsText(file);
    }

    return (
        <StyledConfigFileManager >
            <a ref={downloadButtonRef}
                href={"data:text/json;charset=utf-8," + JSON.stringify(data)}
                download={fileName}>
            </a>
            <input
                ref={inputRef}
                // onChange={()=>handleUploadFile(inputRef.current.files[0])}
                onClick={event => event.target.value = null}
                onChange={handlOnChange}
                type="file"
                className="d-none"
                accept={accept}
            />
 
        </StyledConfigFileManager>
    );
}


export default forwardRef(FileManager);