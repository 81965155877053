import React from 'react';
import PropTypes from 'prop-types'
function SubDropdown({children}){
    return <div id="nav-grid-listing" className="nav-wrapper">
        <ul>
            {children}
        </ul>
    </div>
}
SubDropdown.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
    ]).isRequired
};
export default SubDropdown;