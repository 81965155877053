import React from "react";
import * as PropTypes from "prop-types";
import styled, {css} from "styled-components";


// TODO:
// Make marggin of the arrow a prop.
// Make the color and the background color a prop

const StyledTooltip = styled.span`
  color: #fff;
  white-space: nowrap;
  visibility: ${({visible}) => visible ? "visible" : "hidden"};
  background-color: black;
  text-align: center;
  border-radius: 6px;
  padding: 7px 10px;
  position: absolute;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.3s;
  &:after{
    content: "";
    position: absolute;
    border-width: 5px;
    border-style: solid;
    ${({placement}) => placement === "top" && css`top: 100%; left: 50%;
        border-color: black transparent transparent transparent; margin-left: -3px;`}
    ${({placement}) => placement === "left" && css`top: 50%; left: 100%;
         border-color: transparent  transparent transparent black; margin-top: -4px;`}
    ${({placement}) => placement === "bottom" && css`top: 0%; left: 50%;
        border-color:  transparent transparent black transparent; margin-top: -10px;margin-left: -4px;`}
    ${({placement}) => placement === "right" && css`top: 50%; left: 0%;
        border-color:  transparent black transparent transparent; margin-left: -10px; margin-top: -4px;`}
  }

`
function Tooltip({text, ...props}) {
return (  <StyledTooltip {...props}>{text}</StyledTooltip>);
}

Tooltip.propTypes = {
    text: PropTypes.string.isRequired,
    placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']).isRequired,
    visible: PropTypes.bool,
    style: PropTypes.object
};

export default Tooltip;
